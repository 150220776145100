import request from '@/core/services/request'

/**
 * @description 获取出运计划列表
 * @param {*}
 * @returns
 */
export function getShipmentPlan(data) {
  return request({
    url: '/storage/shippingPlan/v2/listPage',
    method: 'post',
    data,
  })
}

/**
 * @description 获取出运计划产品详情列表
 * @param {*}
 * @returns
 */
export function getShipmentPlanPro(data) {
  return request({
    url: `/storage/shippingPlan/v2/listProduct`,

    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * @description 获取出运计划详情
 * @param {*}
 * @returns
 */
export function getShipmentPlanDetail(data) {
  return request({
    url: '/storage/shippingPlan/v2/detail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 获取出运计划详情
 * @param {*} data
 * @returns
 */
export function getShipmentPlanDetailV1(data) {
  return request({
    url: '/storage/shippingPlan/v2/detailV1',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
/**
 * @description 批量获取出运计划详情
 * @param {*}
 * @returns
 */
export function getShipmentPlanbatchDetail(data) {
  return request({
    url: '/storage/shippingPlan/v2/batchDetail',
    method: 'post',
    data,
  })
}

/**
 * @description 更新出运计划备注
 * @param {*}
 * @returns
 */
export function updateRemark(data = {}) {
  return request({
    url: '/storage/shippingPlan/v2/updateRemark',

    method: 'post',
    data,
  })
}

/**
 * @description 安排出运
 * @param {*}
 * @returns
 */
export function arrangeShipQuery(data) {
  return request({
    url: '/storage/shippingPlan/v2/arrangeShipQuery',
    method: 'post',
    data,
  })
}
/**
 * 获取委托单出运修改信息
 *
 */

export function getUpdate(shippingOrderId) {
  return request({
    url: '/storage/shippingOrder/v2/getUpdate',
    method: 'post',
    params: {
      shippingOrderId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 修改委托单基础数据接口(不包含出运数量变化的修改)
 * @param {*} data
 * @returns
 */
export function updateBasic(data) {
  return request({
    url: '/storage/shippingOrder/v2/updateBasic',
    method: 'post',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // },
  })
}

/**
 * 修改委托单 确认出运数量变化页面查询
 * @param {*} data
 * @returns
 */
export function getConfirmUpdate(data) {
  return request({
    url: '/storage/shippingOrder/v2/getConfirmUpdate',
    method: 'post',
    data,
  })
}
/**
 * 修改委托单-确认出运数量变化
 * @param {*} data
 * @returns
 */
export function confirmUpdate(data) {
  return request({
    url: '/storage/shippingOrder/v2/confirmUpdate',
    method: 'post',
    data,
  })
}
/**
 * 出运委托单状态列表
 * @param {*} data
 * @returns
 */
export function getStatusList(data) {
  return request({
    url: `/storage/shippingOrder/v2/statusList`,

    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 *
 * 新增委托单
 * @returns
 */

export function insertWtd(data) {
  return request({
    url: '/storage/shippingOrder/v2/insert',
    method: 'post',
    data,
  })
}

/**
 * 出运委托单列表 海运空运
 * @param {*} data
 * @returns
 */
export function getWTList(data) {
  return request({
    url: `/storage/shippingOrder/v2/listPage`,
    method: 'post',
    data,
  })
}

/**
 * 出运委托单列表 海运空运
 * @param {*} data
 * @returns
 */
export function getKDWTList(data) {
  return request({
    url: `/storage/express/v2/listPage`,
    method: 'post',
    data,
  })
}

/**
 * 委托单详情
 * @param {*} shipOrderId
 * @returns
 */
export function getDetail(shippingOrderId) {
  return request({
    url: `/storage/shippingOrder/v2/getDetail`,

    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    params: {
      shippingOrderId,
    },
  })
}

/**
 * 出库申请数据
 * @param {*} shipOrderId
 * @returns
 */

export function applyOutStockList(shipOrderProductId) {
  return request({
    url: `/storage/shippingOrder/v2/applyOutStock/page`,
    method: 'post',
    data: {
      shipOrderProductId,
    },
  })
}

/**
 * 出库申请
 * @param {*} shipOrderId
 * @returns
 */

export function applyOutStock(data) {
  return request({
    url: `/storage/shippingOrder/v2/applyOutStock`,
    method: 'post',
    data,
  })
}

/**
 * 导出
 * @param {*} data
 * @returns
 */

export function exportExcel({ shipOrderIdList }) {
  return request({
    url: `/storage/shippingOrder/v2/export/excel`,
    method: 'post',
    responseType: 'blob',
    data: {
      shipOrderIdList,
    },
  })
}

/**
 * 快递导出
 * @param {*} data
 * @returns
 */
export function expressExport(data = {}) {
  return request({
    url: `/storage/express/v2/expressExport`,
    method: 'post',
    data,
  })
}

/**
 * 运输方式下拉数据
 * @param {*} data
 * @returns
 */

export function transpotwayList(data = {}) {
  return request({
    url: `/product/freightSetting/selectAll`,

    method: 'post',
    data,
  })
}

/**
 * 贸易条款
 * @param {*} data
 * @returns
 */
export function tradeList() {
  return request({
    url: `/order/orderAmount/listPage`,
    method: 'post',
    data: {
      amountType: 5,
    },
  })
}

/**
 * 获取仓储设置数据
 */
export function getWhSetting(data) {
  return request({
    url: `${mockBase}/wh/setting`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 删除仓储设置

export function delById(data) {
  return request({
    url: `${mockBase}/wh/del/i`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 查看计划单是否生成委托单

export function queryGenShipping(data) {
  return request({
    url: `/storage/shippingPlan/v2/queryGenShipping`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 订单模块查找委托单列表
export function getShippingOrderById(data) {
  return request({
    url: `/storage/shippingOrder/v2/allPage`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 取消出运查询
 *
 */

export function cancelQuery(data) {
  return request({
    url: `/storage/shippingOrder/v2/cancel/query`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 取消出运操作
 */

export function cancelConfirm(data) {
  return request({
    url: `/storage/shippingOrder/v2/cancel/confirm`,
    method: 'post',
    data,
  })
}

/**
 * 批量更新单证员
 *
 */

export function updateClerkBatch(data = {}) {
  return request({
    url: `/storage/shippingOrder/v2/updateClerkBatch`,
    method: 'post',
    data,
  })
}

/**
 * 手动出运 仅“已出库”状态可以进行“手动出运”操作
 */

export function manualShip(shippingOrderIds = []) {
  return request({
    url: `/storage/shippingOrder/v2/manualShip`,
    method: 'post',
    data: shippingOrderIds,
  })
}

/**
 * 保存运单号
 */

export function saveShipNo(data) {
  return request({
    url: `/storage/express/v2/saveShipNo`,
    method: 'post',
    data,
  })
}

/**
 * 上传发货附件
 */

export function uploadAccessory(data) {
  return request({
    url: `/storage/express/v2/uploadAccessory`,
    method: 'post',
    data,
  })
}

//批量手动签收
export function mutliManualSignIn(data) {
  return request({
    url: `/storage/express/v2/mutliManualSignIn`,
    method: 'post',
    data,
  })
}

// 出运委托-快递 导入
export function expressCostImport(data) {
  return request({
    url: `/storage/shippingOrder/v2/freightAutoImport`,
    method: 'post',
    data,
  })
}

// 出运委托-海运空运 导入
export function shippingCostImport(data) {
  return request({
    url: `/storage/shippingOrder/v2/freightManualImport`,
    method: 'post',
    data,
  })
}

/**
 * 快递列表统计总体，总重，总箱等
 */

export function expressTotalCount(data = {}) {
  return request({
    url: `/storage/express/v2/expressTotalCount`,
    method: 'post',
    data,
  })
}

/**
 * 海运列表统计总体，总重，总箱等
 */

export function seaAirTotalCount(data = {}) {
  return request({
    url: `/storage/shippingOrder/v2/seaAirTotalCount`,
    method: 'post',
    data,
  })
}

/**
 * 出运计划统计总体，总重，总箱等
 */

export function shippingPlanTotalCount(data = {}) {
  return request({
    url: `/storage/shippingPlan/v2/expressTotalCount`,
    method: 'post',
    data,
  })
}

// 快递费用导入模板
export function autoTemplate(data) {
  return request({
    url: `/storage/freightAuto.xlsx`,
    method: 'get',
    responseType: 'blob',
    data,
  })
}

// 空海运费用导入模板
export function manualTemplate(data) {
  return request({
    url: `/storage/freightManual.xlsx`,
    method: 'get',
    responseType: 'blob',
    data,
  })
}

//查询销售订单产品全部的验货照--委托列表发货邮件

export function listAllInspectionProductImage(data) {
  return request({
    url: `/order/inspectionOrder/listAllInspectionProductImage`,
    method: 'post',
    data,
  })
}

//委托单维度查询运费记录
export function shippingFreightRecordSheet(data) {
  return request({
    url: `/storage/shippingFreightRecord/listByOrder`,
    method: 'post',
    data,
  })
}

//产品维度查询运费记录
export function shippingFreightRecordProduct(data) {
  return request({
    url: `/storage/shippingFreightRecord/listByProduct`,
    method: 'post',
    data,
  })
}

// 修改出运计划产品行

export function modifyShippingPlanOrderProduct(data) {
  return request({
    url: `/storage/shippingPlan/v2/modifyShippingPlanOrderProduct`,
    method: 'post',
    data,
  })
}
