const translateConfig = {
  zh: {
    orderDetails: {
      AdjustmentDescription: '调整说明',
      Appendix: '附件',
      Area: '地区',
      BasicInformation: '基本信息',
      BeingRevised: '修改中',
      BulkArrangement: '批量安排',
      Cancelled: '已取消',
      CompanyName: '公司名称',
      PayType: '支付方式',
      OrderTime: '下单时间',
      Search: '搜索',
      Completed: '已完成',
      ContactNumber: '联系电话',
      Contacts: '联系人',
      CreationTime: '创建时间',
      Creator: '创建人',
      CustomerConfirmation: '客户确认中',
      CustomerDiscounts: '客户折扣',
      CustomerDiscountsDescription: '客户折扣说明',
      CustomerInformation: '客户信息',
      CustomerMailbox: '客户邮箱',
      CustomerName: '客户名称',
      CustomerNotes: '客户备注',
      CustomerOrderNumber: '客户订单号',
      CustomerRepresentative: '客户代表',
      CustomizedProduct: '订制品',
      DateOfSigning: '签约日期',
      Delete: '删除',
      Delivered: '已送达',
      DeliveryDate: '交货日期',
      Destination: '目的港',
      DoesItNeedToBePrinted: '是否需要印刷',
      Edit: '编辑',
      ShippingDate: '出运时间',
      EstimatedArrivalTime: '预计到达时间',
      Excipients: '辅料',
      ExpectedDeliveryDate: '期望交期',
      ExportsRise: '出口抬头',
      FeeDescription: '费用说明',
      HasBeenArrangement: '已安排',
      HasBeenConfirmed: '已确认',
      ImprintDocument: '印刷文件',
      InDelivery: '出库中',
      InProcurement: '采购中',
      InTransport: '运输中',
      InternalRemarks: '内部备注',
      Inventory: '库存',
      NoFile: '没有文件',
      OnlinePayment: '在线支付',
      Operate: '操作',
      Operator: '操作人',
      OrderInformation: '订单信息',
      OrderNumber: '订单编号',
      OrderQuantity: '订单数量',
      OrderRemarks: '订单备注',
      OrderSource: '订单来源',
      OrderSupport: '订单支持',
      OrdinaryDelivery: '普通出库',
      OtherFee: '其他费用',
      OutOfWarehouse: '已出库',
      OutboundShipment: '出库/出运',
      PartiallyDelivery: '部分出库',
      PartiallyPurchase: '部分采购',
      PartiallyPurchaseCompleted: '部分采购完成',
      PendingArrangement: '待安排',
      PendingDelivery: '待出库',
      PendingShipment: '待发货',
      PrintingDescription: '印刷描述',
      PrintingMethod: '印刷方式',
      PricingMethod: '计价方式',
      PrintingPosition: '印刷位置',
      TransportationType: '运输方式',
      PlanShipmentDate: '计划装运日期',
      customerDeliveryTime: '客户交期',
      PrintingRenderings: '印刷效果图',
      ProcessingDelivery: '加工出库',
      ProductCode: '产品编码',
      ProductDescription: '产品描述',
      ProductImage: '产品图片',
      ProductInformation: '产品信息',
      ProductList: '产品列表',
      ProductName: '产品名称',
      ProductOperations: '产品运营',
      ProductStatus: '产品状态',
      ProductType: '产品类型',
      PurchaseCompleted: '采购完成',
      PurchaseOrder: '采购订单',
      PurchasingDevelopment: '采购开发',
      QuantityOfThisShipment: '本次出运数量',
      QuantityToBeShipped: '待出运数量',
      QuotationNumber: '报价单号',
      Receiver: '收货人',
      ReceivingCompany: '收货公司',
      RejectedOutOfWarehouse: '出库驳回',
      RemainingStock: '可用库存',
      Remark: '备注',
      Rendering: '效果图(点击图片可修改)',
      RenderingsStatus: '效果图状态',
      Return: '返回',
      ReturnQuantity: '退货数量',
      RevokeArrangement: '撤销安排',
      SellingPrice: '售价',
      SerialNumber: '序号',
      SetupCharge: 'Setup Charge',
      ShipmentDate: '装运日期',
      ShipmentPlanNumber: '出运计划单号',
      Shipped: '已出运',
      NotShipped: '未出运',
      ShippingAddress: '收货地址',
      ShippingFee: 'Shipping Fee',
      ShippingInformation: '出运信息',
      ShippingMethod: '运输方式',
      ShippingOrderNumber: '出运委托单号',
      ShippingPort: '装运港',
      ShippingStatus: '出运状态',
      Specification: '规格',
      StandardProduct: '标准品',
      Status: '状态',
      TimeAdjustment: '调整时间',
      TimeRejection: '驳回说明',
      ToBeConfirmed: '待确认',
      Total: '总计',
      TotalDiscount: '总折扣',
      TotalNumberOfBoxes: '总箱数',
      NumberOfBoxes: '箱数',
      TotalQuantity: '总数量',
      Quantity: '数量',
      TotalVolume: '总体积',
      Volume: '体积',
      TotalWeight: '总重量',
      Weight: '重量',
      TradeWay: '贸易方式',
      TransactionsCosts: '交易/成本',
      Type: '类型',
      UnsettledReason: '未结算原因',
      ViewContract: '查看合同',
      ViewMarks: '查看Marks',
      ViewTheRenderings: '效果图查看',
      WaybillNumber: '运单号',
      WaybillNumberEnd: '运单号(尾程)',
      WhetherToRefundTax: '是否退税',
      NotoutOfstock: '未出库',
      DefaultAddress: '默认地址',
      MallOrderNumber: '商城订单号',
      OrderDate: '下单日期',
      ProductNumber: '产品编号',
      TotalAmount: '订单总金额',
      CheckOrder: '查看订单',
      DealWith: '处理',
      Renderings: '效果图',
      Processed: '已处理',
      NotProcessed: '未处理',
      All: '全部',
      Billingmessage: '收款信息',
      Paymentcurrency: '收款币种',
      Tip: '提示：非美国本土大陆收货地址，可能出现运费波动或者派送失败！',
      NoArtWork: '客户未上传 Artwork',
      toConfirm: '待客户确认效果图',
      PlannedShipmentQuantity: '计划装运数量',
      PlannedShippingDate: '计划装运日期',
      CustomerDeliveryTime: '客户交期',
      OverfillQuantity: '溢装数量',
    },
  },
  en: {
    orderDetails: {
      AdjustmentDescription: 'Adjustment Description',
      Appendix: 'Appendix',
      Area: 'Region',
      BasicInformation: 'Basic Information',
      BeingRevised: 'Being Revised',
      BulkArrangement: 'Bulk Arrangement',
      Cancelled: 'Cancelled',
      CompanyName: 'Company',
      PayType: 'Payment Method',
      OrderTime: 'Order Time',
      Search: 'Search',
      Completed: 'Completed',
      ContactNumber: 'Contact Number',
      Contacts: 'Contacts',
      CreationTime: 'Creation Time',
      Creator: 'Creator',
      CustomerConfirmation: 'Customer Confirmation',
      CustomerDiscounts: 'Customer Discounts',
      CustomerDiscountsDescription: 'Customer Discounts Description',
      CustomerInformation: 'Customer Information',
      CustomerMailbox: 'Customer Email',
      CustomerName: 'Customer Name',
      CustomerNotes: 'Customer Notes',
      CustomerOrderNumber: 'PO#',
      CustomerRepresentative: 'Sales Rep',
      CustomizedProduct: 'Customized Product',
      DateOfSigning: 'Order Date',
      Delete: 'Delete',
      Delivered: 'Delivered',
      DeliveryDate: 'Delivery Date',
      Destination: 'Destination',
      DoesItNeedToBePrinted: 'Does it need to be printed',
      Edit: 'Edit',
      ShippingDate: 'Shipping Date',
      EstimatedArrivalTime: 'Estimated Arrival Time',
      Excipients: 'Excipients',
      ExpectedDeliveryDate: 'Expected Delivery Date',
      ExportsRise: 'RP/RG/SW',
      FeeDescription: 'Fee Description',
      HasBeenArrangement: 'Has Been Arrangement',
      HasBeenConfirmed: 'Has Been Confirmed',
      ImprintDocument: 'Art File',
      InDelivery: 'In Delivery',
      InProcurement: 'In Procurement',
      InTransport: 'In Transport',
      InternalRemarks: 'Internal Remarks',
      Inventory: 'Inventory',
      NoFile: 'No File',
      OnlinePayment: 'Online Payment',
      Operate: 'Operate',
      Operator: 'Operator',
      OrderInformation: 'Order Information',
      OrderNumber: 'Order Number',
      OrderQuantity: 'Order Quantity',
      OrderRemarks: 'Order Remarks',
      OrderSource: 'Order Source',
      OrderSupport: 'Sales Assistant',
      OrdinaryDelivery: 'Ordinary Delivery',
      OtherFee: 'Other Fee',
      OutOfWarehouse: 'Stock- Out',
      OutboundShipment: 'Outbound/Shipment',
      PartiallyDelivery: 'Partially Delivery',
      PartiallyPurchase: 'Partially Purchase',
      PartiallyPurchaseCompleted: 'Partially Purchase Completed',
      PendingArrangement: 'Pending Arrangement',
      PendingDelivery: 'Pending Delivery',
      PendingShipment: 'Pending Shipment',
      PrintingDescription: 'Printing Description',
      PrintingMethod: 'Imprint Method',
      PricingMethod: 'Pricing Method',
      PrintingPosition: 'Imprint Location',
      TransportationType: 'Transportation type',
      PlanShipmentDate: 'Plan Shipment Date',
      customerDeliveryTime: 'Customer Delivery Time',
      PrintingRenderings: 'Printing Renderings',
      ProcessingDelivery: 'Processing Delivery',
      ProductCode: 'Item No.',
      ProductDescription: 'Product Description',
      ProductImage: 'Product Image',
      ProductInformation: 'Product Information',
      ProductList: 'Product List',
      ProductName: 'Item Name',
      ProductOperations: 'Product owner',
      ProductStatus: 'Product Status',
      ProductType: 'Product Type',
      PurchaseCompleted: 'Purchase Completed',
      PurchaseOrder: 'Purchase Order',
      PurchasingDevelopment: 'Purchasing Development',
      QuantityOfThisShipment: 'Quantity Of This Shipment',
      QuantityToBeShipped: 'Quantity To Be Shipped',
      QuotationNumber: 'Quotation Number',
      Receiver: 'Receiver',
      ReceivingCompany: 'Receiving Company',
      RejectedOutOfWarehouse: 'Rejected Out Of Warehouse',
      RemainingStock: 'Remaining Stock',
      Remark: 'Remark',
      Rendering: 'Rendering (Click On The Picture To Modify)',
      RenderingsStatus: 'Virtual Proof Status',
      Return: 'Return',
      ReturnQuantity: 'Return Quantity',
      RevokeArrangement: 'Revoke Arrangement',
      SellingPrice: 'Unit Price',
      SerialNumber: 'S/N',
      SetupCharge: 'Setup Charge',
      ShipmentDate: 'Shipment Date',
      ShipmentPlanNumber: 'Shipment Plan Number',
      Shipped: 'Shipped',
      NotShipped: 'Not Shipped',
      ShippingAddress: 'Shipping Address',
      ShippingFee: 'Shipping Fee',
      ShippingInformation: 'Shipping Information',
      ShippingMethod: 'Shipping Method',
      ShippingOrderNumber: 'Shipping Order Number',
      ShippingPort: 'Shipping Port',
      ShippingStatus: 'Shipping Status',
      Specification: 'Specification',
      StandardProduct: 'Standard Product',
      Status: 'Status',
      TimeAdjustment: 'Time Adjustment',
      TimeRejection: 'Time Rejection',
      ToBeConfirmed: 'To confirm',
      Total: 'Total',
      TotalDiscount: 'Total Discount',
      TotalNumberOfBoxes: 'Total Number Of Boxes',
      NumberOfBoxes: 'Number Of Boxes',
      TotalQuantity: 'Total Quantity',
      Quantity: 'Quantity',
      TotalVolume: 'Total Volume',
      Volume: 'Volume',
      TotalWeight: 'Total Weight',
      Weight: 'Weight',
      TradeWay: 'Trade Way',
      TransactionsCosts: 'Transactions/Costs',
      Type: 'Type',
      UnsettledReason: 'Unsettled Reason',
      ViewContract: 'View Contract',
      ViewMarks: 'View Marks',
      ViewTheRenderings: 'View The Renderings',
      WaybillNumber: 'Waybill Number(Whole vessel/The first vessel)',
      WaybillNumberEnd: 'Waybill Number(End vessel)',
      WhetherToRefundTax: 'Whether To Refund Tax',
      NotoutOfstock: 'Not out of stock',
      DefaultAddress: 'default address',
      MallOrderNumber: 'Mall order number',
      OrderDate: 'Order date',
      ProductNumber: 'Product number',
      TotalAmount: 'The total amount of orders',
      CheckOrder: 'check order',
      DealWith: 'deal with',
      Renderings: 'renderings',
      Processed: 'Processed',
      NotProcessed: 'Not processed',
      All: 'all',
      Billingmessage: 'Billing message',
      Paymentcurrency: 'Payment currency',
      Tip: 'tip: The above address is not a regular continental U.S. shipping address, which may result in higher shipping charges or failed delivery!',
      NoArtWork: 'Artwork not uploaded',
      toConfirm: 'Pending customer approval',
      PlannedShipmentQuantity: 'Planned Shipment Quantity',
      PlannedShippingDate: 'Planned Shipping Date',
      CustomerDeliveryTime: 'Customer Delivery Time',
      OverfillQuantity: 'Overfill Quantity',
    },
  },
}

export default translateConfig
