import {
  purchasingDCreatePurchaseOrder,
  supplierGetDetail,
  purchaseInsertPO,
  purchaseGetDetailForEdit,
  purchaseUpdatePO,
  purchaseCheckChange,
  orderGetDict,
  contractData,
  checkDeletePurchaseProduct,
  financeCurrencyList,
  getPurchaseContract,
  lookPurchaseStockUp,
  orderGetDetailByOrderCode,
  querySealedContract,
  orderNewGetDetailByOrderCode,
  orderShipInfo,
  addressProduct,
  orderAlignOrderList,
  getPriceSheetDetail,
  printOrderPriceSheet,
  purchasingDGetDetailForEdit,
  purchaseGetDetailForShow,
  listSendMailInfo,
  calcShipFee,
  pageSalesOrderRend,
  matchEnquiryOrder,
  listEnclosureByDocId,
  updateOrderEnquirySalesOrderNumber,
  saveOrderShipPlanVO,
  getOrderShipPlanProduct,
} from '@/core/services/api/order'
import { URL_COMPRESS } from '@/config'
import { decode, deepCopy } from 'kits'
class orderInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * Asynchronously creates a purchase order in the "purchasingDCreatePurchaseOrder" module.
   *
   * @param {Object} options - An object containing the following parameters:
   *   - {Array} businessIds - An array of business IDs.
   * @return {Promise} A promise that resolves to the created purchase order.
   */
  async purchasingDCreatePurchaseOrder({ businessIds }) {
    return await purchasingDCreatePurchaseOrder({ businessIds })
  }

  /**
   * 获取供应商详细信息。
   *
   * @param {Object} params - 获取供应商详细信息的参数。
   * @param {string} params.id - 供应商的ID。
   * @return {Promise<Object>} 返回一个解析为供应商详细信息的 Promise。
   */
  async supplierGetDetail({ id }) {
    return await supplierGetDetail({ id })
  }

  /**
   * @description (采购订单)新增实体
   * @param {*} data.accountName 户名
   * @param {*} data.accountType 1.9.3CBS 账户类型：（1：对公账户 、2：个人银行卡、3：支付宝、4：网拍代付）
   * @param {*} data.actualDeliveryDate 实际交货交期
   * @param {*} data.agreementEndDate 协议结束日期
   * @param {*} data.amountDrawbackTax	 退税金额
   * @param {*} data.amountExcludingTax 未税金额
   * @param {*} data.amountIncludingTax 含税金额
   * @param {*} data.amountPaid 已付金额
   * @param {*} data.amountPayable 应付金额
   * @param {*} data.amountRefunded	 退款金额
   * @param {*} data.amountTotal	 总金额
   * @param {*} data.auditObjectDTO	 提交审核
   * @param {*} data.purchasePaymentMethodEditDTOList	 付款方式子表
   * @param {*} data.purchaseProductEditDTOList	 产品子表
   * @param {*} data.purchaser	 买方
   * @param {*} data.receivingAddress	 收货地址
   * @param {*} data.remark	 备注
   * @param {*} data.sealedContract	 已盖章合同
   * @param {*} data.state	 状态
   * @param {*} data.submitType	 按钮类型 1保存 2提交
   * @param {*} data.warehouseFlag	 是否入库（1：是；0：否）
   * @returns
   */
  async purchaseInsertPO(data) {
    return await purchaseInsertPO(data)
  }

  /**
   * @description 获取采购订单详情
   * @param {*} businessId 采购订单id
   */
  async purchaseGetDetailForEdit({ businessId }) {
    return await purchaseGetDetailForEdit({ businessId })
  }

  /**
   * @description (采购订单)编辑 保存
   * @param {*} data.accountName 户名
   * @param {*} data.accountType 1.9.3CBS 账户类型：（1：对公账户 、2：个人银行卡、3：支付宝、4：网拍代付）
   * @param {*} data.actualDeliveryDate 实际交货交期
   * @param {*} data.agreementEndDate 协议结束日期
   * @param {*} data.amountDrawbackTax	 退税金额
   * @param {*} data.amountExcludingTax 未税金额
   * @param {*} data.amountIncludingTax 含税金额
   * @param {*} data.amountPaid 已付金额
   * @param {*} data.amountPayable 应付金额
   * @param {*} data.amountRefunded	 退款金额
   * @param {*} data.amountTotal	 总金额
   * @param {*} data.auditObjectDTO	 提交审核
   * @param {*} data.purchasePaymentMethodEditDTOList	 付款方式子表
   * @param {*} data.purchaseProductEditDTOList	 产品子表
   * @param {*} data.purchaser	 买方
   * @param {*} data.receivingAddress	 收货地址
   * @param {*} data.remark	 备注
   * @param {*} data.sealedContract	 已盖章合同
   * @param {*} data.state	 状态
   * @param {*} data.submitType	 按钮类型 1保存 2提交
   * @param {*} data.warehouseFlag	 是否入库（1：是；0：否）
   * @returns
   */
  async purchaseUpdatePO(data) {
    return await purchaseUpdatePO(data)
  }

  async purchaseCheckChange(data) {
    return await purchaseCheckChange(data)
  }

  /**
   * @description  获取溢装数比例
   * @param {*} param0  amountType
   * @returns
   */
  async orderGetDict({ amountType }) {
    return await orderGetDict({
      amountType,
    })
  }

  /**
   * @description 获取买方下拉字典
   * @param {*} param0
   * @returns
   */
  async contractData({ tenantId }) {
    return await contractData({ tenantId })
  }

  /**
   * @description 删除采购订单产品校验
   * @param {*} param0
   * @returns
   */
  async checkDeletePurchaseProduct({ purchaseOrderNumber, purchaseProductId }) {
    return await checkDeletePurchaseProduct({
      purchaseOrderNumber,
      purchaseProductId,
    })
  }

  async financeCurrencyList() {
    return await financeCurrencyList()
  }

  /**
   * @description 获取采购合同
   * @param {*} param0
   * @returns
   */
  async getPurchaseContract({ businessId }) {
    return await getPurchaseContract({ businessId })
  }

  async lookPurchaseStockUp({ businessId }) {
    return await lookPurchaseStockUp({ businessId })
  }

  /**
   *
   * @param {string} orderCode - 订单号.
   */
  async orderGetDetailByOrderCodeApi(data) {
    const { orderCode } = data
    return await orderGetDetailByOrderCode({
      orderCode,
    })
  }
  /**
   *
   * @param {string} numbers - 采购单号.
   */
  async querySealedContractApi(numbers) {
    return await querySealedContract(numbers)
  }

  /**
   *
   * @param {string} orderCode - 订单号.
   */
  async orderNewGetDetailByOrderCodeApi(data) {
    const { orderCode } = data
    return await orderNewGetDetailByOrderCode({ orderCode })
  }

  /**
   *
   * @param {string} orderCode - 订单号.
   */
  async orderShipInfoApi(data) {
    const { orderCode } = data
    return await orderShipInfo({ orderCode })
  }
  /**
   *
   * @param {string} addressId -地址id.
   */
  async addressProductApi(data) {
    const { addressId } = data
    return await addressProduct({ addressId })
  }
  /**
   *
   * @param [alignOrderDTOS] 	安排订单集合
   */
  async orderAlignOrderListApi(data) {
    return await orderAlignOrderList(data)
  }
  /**
   *
   * @param string priceSheetCode 	报价单号
   */
  async getPriceSheetDetailApi(data) {
    const { priceSheetCode } = data
    return await getPriceSheetDetail({ priceSheetCode })
  }
  /**
   *
   * @param string enquiryId 	业务id
   */
  async printOrderPriceSheetApi(data) {
    const { enquiryId } = data
    return await printOrderPriceSheet({ enquiryId })
  }

  /**
   *
   * @param string numberPrefix
   */
  async purchasingDGetDetailForEditApi(data) {
    const { numberPrefix } = data
    return await purchasingDGetDetailForEdit({ numberPrefix })
  }
  /**
   *
   * @param string businessId 业务id
   */
  async purchaseGetDetailForShowApi(data) {
    const { businessId } = data
    return await purchaseGetDetailForShow({ businessId })
  }
  /**
   *
   * @param string businessId 业务id
   */
  async listSendMailInfoApi(data) {
    const { businessId } = data
    return await listSendMailInfo({ businessId })
  }

  /**
   *
   * @param Array data
   */
  async calcShipFeeApi(data) {
    return await calcShipFee(data)
  }

  async pageSalesOrderRendApi(data) {
    const { orderBy, pageLe, pageNo, salesOrderCode } = data
    let res = await pageSalesOrderRend({
      orderBy,
      pageLe,
      pageNo,
      salesOrderCode,
    })
    if (res?.code === '000000') {
      if (Array.isArray(res?.data?.data)) {
        res.data.data = res.data.data.map((order) => {
          let imgs = []
          // 2 没有文件
          order.orderProducts = order.orderProducts.filter(
            (pro) => pro.rendingsStatus !== 2
          )
          order.orderProducts.forEach((pro) => {
            if (pro.rendingsName) {
              pro.rendingsName = JSON.parse(pro.rendingsName)
            } else {
              pro.rendingsName = []
            }
            if (pro.rendingsUrl) {
              pro.rendingsUrl = pro.rendingsUrl.split(',')
              pro.rendingsUrl.forEach((url, index) => {
                let name = pro.rendingsName[index]?.name || ''
                if (name) {
                  name = decode(name)
                }
                imgs.push({
                  url,
                  name,
                  rendingsStatus: pro.rendingsStatus,
                  orderCode: order.orderCode,
                })
              })
            }
          })
          order.order_id = order.orderId
          order.order_code = order.orderCode
          order.previewImgs = imgs.map((img) => img.url)
          order.imgs = imgs.map((img) => {
            img = {
              ...img,
              url: `${img.url}${URL_COMPRESS}`,
            }
            return img
          })

          return order
        })
      }
    }
    return res
  }

  // 根据客户订单号和客户邮箱匹配Sage询盘订单    customOrderCode=客户订单号 email=客户邮箱
  matchEnquiryOrderApi({ customOrderCode, email }) {
    return matchEnquiryOrder({ customOrderCode, email })
  }

  // 根据docId获取附件
  listEnclosureByDocIdApi(docId) {
    return listEnclosureByDocId({ docId })
  }

  // 更新Sage询盘单销售订单号
  /*
    docId：Sage订单docId
    salesOrderNumber：销售订单号
    enquiryId：业务id
  */
  updateOrderEnquirySalesOrderNumberApi({
    docId,
    salesOrderNumber,
    enquiryId,
  }) {
    return updateOrderEnquirySalesOrderNumber({
      docId,
      salesOrderNumber,
      enquiryId,
    })
  }

  saveOrderShipPlanVOApi(tableData, initTableData, orderInfo) {
    const addressList = getSaveAddressList(tableData, initTableData, orderInfo)
    return saveOrderShipPlanVO(addressList)
  }

  getOrderShipPlanProductApi(piNumber) {
    return getOrderShipPlanProduct({
      piNumber,
    }).then((res) => {
      if (res?.code === '000000') {
        const addressItem = res?.data
        addressItem.deleteFlag = 1
        addressItem.addressConfigId = ''
        addressItem.addressId = ''
        addressItem.country = ''
        addressItem.receivingCompany = ''
        addressItem.receivingPerson = ''
        addressItem.receivingPhone = ''
        addressItem.destination = ''
        // 清空相关数据
        addressItem.productDetails = addressItem.productDetails.map((item) => {
          if (item.productSubList) {
            item.productSubList = item.productSubList.map((subItem) => {
              subItem.updateFlag = 1
              subItem.planShippingQuantity = 0
              return subItem
            })
          }
          if (item.childProducts) {
            item.childProducts = item.childProducts.map((childProduct) => {
              if (childProduct.productSubList) {
                childProduct.productSubList = childProduct.productSubList.map(
                  (productSub) => {
                    productSub.updateFlag = 1
                    productSub.planShippingQuantity = 0
                    return productSub
                  }
                )
              }
              return childProduct
            })
          }
          return item
        })
        return addressItem
      }
    })
  }
}

orderInteractor._instance = new orderInteractor()

export const OrderInteractor = orderInteractor.getInstance()

function getSaveAddressList(tableData, initTableData, orderInfo) {
  let addressList = deepCopy(tableData)
  addressList = addressList.map((address) => {
    return handleAddressData(address, initTableData, orderInfo)
  })
  return addressList
}

function handleAddressData(address, initTableData, orderInfo) {
  const productDetails = []
  const handledShippingPlanOrderProductId = [] //已处理的shippingPlanOrderProductId
  // 判断地址是否是新增
  const isNewAddress = !initTableData.find(
    (item) => item.addressConfigId === address.addressConfigId
  )
  address.productDetails.forEach((productDetail) => {
    // 避免重复添加
    if (
      !handledShippingPlanOrderProductId.includes(
        productDetail.shippingPlanOrderProductId
      )
    ) {
      if (
        !productDetail.childProducts ||
        productDetail.childProducts?.length === 0
      ) {
        // 没有拆分，主直接合并
        // productSubList
        const productSubList = deepCopy(productDetail.productSubList)
        const newProductSubList = []
        // 处理productSubList和出运计划相关数据
        productSubList.forEach((productSub) => {
          const matched = address.productDetails.find(
            (item) => item.productSubId === productSub.productSubId
          )
          if (matched) {
            productSub.planShippingQuantity = matched.planShippingQuantity
            productSub.shippingMethodId = matched.shippingMethodId
            productSub.shippingMethod = matched.shippingMethod
            productSub.shippingMethodType = matched.shippingMethodType
            productSub.planShippingDate = matched.planShippingDate
            productSub.firmFlag = matched.firmFlag
            productSub.customerDeliveryDate = matched.customerDeliveryDate
          }
          if (isNewAddress) {
            productSub.productSubId = ''
            productSub.useAmount = ''
            productSub.updateFlag = 1
            productSub.overLoadingQuantity = ''
          }
          newProductSubList.push(productSub)
        })
        productDetail.productSubList = newProductSubList

        // if (
        //   !handledShippingPlanOrderProductId.includes(
        //     productDetail.shippingPlanOrderProductId
        //   )
        // ) {
        handledShippingPlanOrderProductId.push(
          productDetail.shippingPlanOrderProductId
        )
        if (isNewAddress) {
          productDetail.shippingPlanOrderProductId = ''
        }

        productDetails.push(productDetail)
        // }
      } else {
        // 处理主产品维度的planShippingQuantity
        const mainMatched = address.productDetails.find(
          (item) =>
            item.shippingPlanOrderProductId ===
            productDetail.shippingPlanOrderProductId
        )
        if (mainMatched) {
          productDetail.productSubList[0].planShippingQuantity =
            mainMatched.planShippingQuantity
        }
        // 处理childProducts
        const childProducts = []
        const handledChildProductsShippingPlanOrderProductIds = [] // 子产品中已处理的shippingPlanOrderProductId
        productDetail.childProducts.forEach((childProduct) => {
          if (
            !handledChildProductsShippingPlanOrderProductIds.includes(
              childProduct.shippingPlanOrderProductId
            )
          ) {
            const productSubList = deepCopy(childProduct.productSubList)
            const newProductSubList = []
            productSubList.forEach((productSub) => {
              const matched = productDetail.childProducts.find(
                (item) => item.productSubId === productSub.productSubId
              )
              if (matched) {
                productSub.planShippingQuantity = matched.planShippingQuantity
                productSub.shippingMethodId = matched.shippingMethodId
                productSub.shippingMethod = matched.shippingMethod
                productSub.shippingMethodType = matched.shippingMethodType
                productSub.planShippingDate = matched.planShippingDate
                productSub.firmFlag = matched.firmFlag
                productSub.customerDeliveryDate = matched.customerDeliveryDate
              }
              if (isNewAddress) {
                productSub.productSubId = ''
                productSub.useAmount = ''
                productSub.updateFlag = 1
                productSub.overLoadingQuantity = ''
              }
              newProductSubList.push(productSub)
            })
            childProduct.productSubList = newProductSubList
            handledChildProductsShippingPlanOrderProductIds.push(
              childProduct.shippingPlanOrderProductId
            )
            if (isNewAddress) {
              childProduct.shippingPlanOrderProductId = ''
            }
            childProducts.push(childProduct)
          }
        })
        productDetail.childProducts = childProducts
        handledShippingPlanOrderProductId.push(
          productDetail.shippingPlanOrderProductId
        )
        if (isNewAddress) {
          productDetail.shippingPlanOrderProductId = ''
        }

        productDetails.push(productDetail)
      }
    }
  })
  address.piNumber = orderInfo.piNumber
  address.updateRemark = orderInfo.updateRemark
  address.productDetails = productDetails
  if (isNewAddress) {
    address.shippingPlanOrderId = ''
  }
  return address
}
