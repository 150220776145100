import request from '@/core/services/request'

//委托单列表
export function getListPage(data) {
  return request({
    url: '/storage/shippingOrder/listPage',
    method: 'post',
    data,
  })
}

// 新增委托单

export function insertPO(data) {
  return request({
    url: '/storage/shippingOrder/insertPO',
    method: 'post',
    data,
  })
}

// 根据id查找

export function getDetail(shipingOrderId) {
  return request({
    url: '/storage/shippingOrder/getDetail',
    method: 'post',
    params: {
      businessId: shipingOrderId,
    },
  })
}

// 获取贸易条款
export function tradeList() {
  return request({
    url: '/storage/shippingOrder/tradeList',
    method: 'post',
  })
}

// 货运方式

export function shippingMethodList() {
  return request({
    url: '/storage/shippingOrder/shippingMethodList',
    method: 'post',
  })
}

// 交货方式
export function deliveryModeList() {
  return request({
    url: '/storage/shippingOrder/deliveryModeList',
    method: 'post',
  })
}

// 物流仓库地址
export function deliveryAddressList() {
  return request({
    url: '/storage/shippingOrder/deliveryAddressList',
    method: 'post',
  })
}

// 获取状态

export function orderStatusList() {
  return request({
    url: '/storage/shippingOrder/orderStatusList',
    method: 'post',
  })
}

// 产品列表-根据产品ID和数量获取数量阶梯数据

export function getStepByProductId(data = {}) {
  return request({
    url: '/product/product/getStepByProductId',
    method: 'post',
    data,
  })
}

//查看进程

export function getProgressList(data = {}) {
  return request({
    url: '/storage/shippingOrder/getList',
    method: 'post',
    data,
  })
}

//更新运单号

export function updateShipNo(data = {}) {
  return request({
    // url: '/storage/shippingOrder/manualSignIn',
    url: '/storage/express/v2/updateShipNo',
    method: 'post',
    data,
  })
}

export function manualSignIn(data = {}) {
  return request({
    // url: '/storage/shippingOrder/manualSignIn',
    url: '/storage/express/v2/manualSignIn',
    method: 'post',
    data,
  })
}

// 填写运单号获取邮件配置信息并发送邮件
export function getShippingOrderMailConfig(data) {
  return request({
    url: `/storage/shippingOrder/v2/email/content`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 发送邮件回调

export function sendEmailCallBack(shippingOrderId) {
  return request({
    url: `/storage/shippingOrder/v2/sendEmailCallBack`,
    method: 'post',
    params: { shippingOrderId },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 获取netPrice
export function getNetPrice({
  amount, // 产品数量
  orderProductUniqueId, // 订单产品唯一id
  printingPositionValueEn, // 印刷位置英文
  printingWayEn, // 印刷方式英文
  productId, // 产品id
  sku, // sku
  valuationMethodValueEn, // 计价方式英文
  valuationMethodValue,
}) {
  return request({
    url: '/product/product/calculate/netPrice',
    method: 'post',
    data: {
      amount, // 产品数量
      orderProductUniqueId, // 订单产品唯一id
      printingPositionValueEn, // 印刷位置英文
      printingWayEn, // 印刷方式英文
      productId, // 产品id
      sku, // sku
      valuationMethodValueEn, // 计价方式英文
      valuationMethodValue,
    },
  })
}
