import {
  getCompanyList,
  customerSourceList,
  getCustomerList,
  getCustomerListAll,
  userHasRecord,
  addRemark,
  getCompanyDetail,
  toBlackCompany,
  outBlackCompany,
  infoRemark,
  checkEmail,
  selectConpanyByEmail,
  insertCustomer,
  getCompanyVO,
  insertPORemark,
  remarkListData,
  selectAllCustomerByInfoIds,
  selectAllCustomerByManagerIds,
  insertReceivingAddress,
  updateShipAddress,
} from '@/core/services/api/new-customer-manage'

class customerManageInteractor {
  static getInstance() {
    return this._instance
  }

  /**
   * 公司列表
   * @param {*} data
   * @returns
   */

  async getCompanyList({
    pageLe,
    pageNo,
    authors,
    companyCode,
    companyId,
    companyLevel,
    companyName,
    email,
    orderBy,
    queryType,
    status,
    tenantId,
    keyIds,
  }) {
    try {
      const res = await getCompanyList({
        pageLe,
        pageNo,
        authors,
        companyCode: _trim(companyCode),
        companyId,
        companyLevel,
        companyName: _trim(companyName),
        email: _trim(email),
        orderBy,
        queryType,
        status,
        tenantId,
        keyIds,
      })
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 客户列表我的客户
   * @param {*} data
   * @returns
   */

  async getCustomerList({
    belongerId,
    chargeId,
    businessRemark,
    companyId,
    companyName,
    creatorId,
    curUserId,
    customerCharge,
    customerInfo,
    customerRank,
    customerSourceId,
    customerType,
    editCustomer,
    customerCode,
    email,
    endCreateTime,
    endSeasTime,
    endTimeRemaining,
    highSeas,
    highSeasType,
    limit,
    linkman,
    mktRemark,
    orderBy,
    pageLe,
    pageNo,
    phone,
    removeCharge,
    score,
    seasCustomer,
    sourceName,
    startCreateTime,
    startSeasTime,
    startTimeRemaining,
    status,
    tag,
    tenantId,
    toSeaType,
    totalOrderAmountRmb,
    totalOrderAmountUs,
    totalOrderNum,
    transferCustomer,
    users,
    keyIds,
    timeRemaining,
  }) {
    try {
      const res = await getCustomerList({
        belongerId,
        chargeId,
        businessRemark,
        companyId,
        companyName,
        creatorId,
        curUserId,
        customerCharge,
        customerInfo,
        customerRank,
        customerSourceId,
        customerType,
        editCustomer,
        customerCode: _trim(customerCode),
        email: _trim(email),
        endCreateTime,
        endSeasTime,
        endTimeRemaining,
        highSeas,
        highSeasType,
        limit,
        linkman: _trim(linkman),
        mktRemark,
        orderBy,
        pageLe,
        pageNo,
        phone: _trim(phone),
        removeCharge,
        score,
        seasCustomer,
        sourceName,
        startCreateTime,
        startSeasTime,
        startTimeRemaining,
        status,
        tag,
        tenantId,
        toSeaType,
        totalOrderAmountRmb,
        totalOrderAmountUs,
        totalOrderNum,
        transferCustomer,
        users,
        keyIds,
        timeRemaining,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getCustomerListAll({
    belongerId,
    chargeId,
    businessRemark,
    companyId,
    companyName,
    creatorId,
    curUserId,
    customerCharge,
    customerInfo,
    customerRank,
    customerSourceId,
    customerType,
    editCustomer,
    customerCode,
    email,
    endCreateTime,
    endSeasTime,
    endTimeRemaining,
    highSeas,
    highSeasType,
    limit,
    linkman,
    mktRemark,
    orderBy,
    pageLe,
    pageNo,
    phone,
    removeCharge,
    score,
    seasCustomer,
    sourceName,
    startCreateTime,
    startSeasTime,
    startTimeRemaining,
    status,
    tag,
    tenantId,
    toSeaType,
    totalOrderAmountRmb,
    totalOrderAmountUs,
    totalOrderNum,
    transferCustomer,
    users,
    keyIds,
    timeRemaining,
  }) {
    try {
      const res = await getCustomerListAll({
        belongerId,
        chargeId,
        businessRemark,
        companyId,
        companyName,
        creatorId,
        curUserId,
        customerCharge,
        customerInfo,
        customerRank,
        customerSourceId,
        customerType,
        editCustomer,
        customerCode: _trim(customerCode),
        email: _trim(email),
        endCreateTime,
        endSeasTime,
        endTimeRemaining,
        highSeas,
        highSeasType,
        limit,
        linkman: _trim(linkman),
        mktRemark,
        orderBy,
        pageLe,
        pageNo,
        phone: _trim(phone),
        removeCharge,
        score,
        seasCustomer,
        sourceName,
        startCreateTime,
        startSeasTime,
        startTimeRemaining,
        status,
        tag,
        tenantId,
        toSeaType,
        totalOrderAmountRmb,
        totalOrderAmountUs,
        totalOrderNum,
        transferCustomer,
        users,
        keyIds,
        timeRemaining,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 客户来源下拉数据
   */
  async customerSourceList(data) {
    try {
      const res = await customerSourceList(data)
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  async userHasRecord(data = {}) {
    try {
      const res = await userHasRecord(data)
      if (res && res.code === '000000') {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 添加备注(公司和客户通用)
   */

  async addRemark({
    companyId,
    creator,
    creatorEn,
    customerRemarkId,
    infoId,
    remark,
    remarkType, //	备注类型:1:公司备注，2：客户备注
  }) {
    try {
      const res = await addRemark({
        companyId,
        creator,
        creatorEn,
        customerRemarkId,
        infoId,
        remark,
        remarkType,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async infoRemark({
    infoId,
    remark,
    remarkType, // 1：mkt备注，2：业务备注
  }) {
    try {
      const res = await infoRemark({
        infoId,
        remark,
        remarkType,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 公司详情查询
  async getCompanyDetail(companyId) {
    try {
      const res = await getCompanyDetail(companyId)
      if (res && res.code === '000000') {
        return res.data
      } else {
        return {}
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 公司加入黑名单

  async toBlackCompany(reqData) {
    const {
      blackRemark,
      companyAddress,
      companyId,
      companyName,
      faxNumber,
      postcode,
      remark,
      website,
    } = reqData
    try {
      const res = await toBlackCompany({
        blackRemark,
        companyAddress,
        companyId,
        companyName,
        faxNumber,
        postcode,
        remark,
        website,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 公司转出黑名单
  async outBlackCompany(companyId) {
    try {
      const res = await outBlackCompany(companyId)
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 校验邮箱是否重复
   */
  async checkEmail(email) {
    try {
      const res = await checkEmail(email)
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 新增客户根据邮箱查询公司

  async selectConpanyByEmail({ customerEmail, pageLe, pageNo }) {
    try {
      const res = await selectConpanyByEmail({
        customerEmail,
        pageLe,
        pageNo,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  // 新增客户

  async insertCustomer(data) {
    const {
      belongerId,
      belongerName,
      belongerNameEn,
      businessId,
      companyAddress,
      companyId,
      companyName,
      customerCode,
      customerName,
      customerRank,
      customerSourceId,
      customerSourceName,
      customerSourceNameEn,
      customerType,
      dateSource,
      deletePaymentIds,
      email,
      faxNumber,
      highSeas,
      infoId,
      paymentId,
      phone,
      postcode,
      receivingAddressConfigDTOS,
      remark,
      seasTime,
      skype,
      tenantId,
      timeRemaining,
      website,
      wechat,
      whatsapp,
      auditObjectPaymentDTO,
      auditObjectDTO,
      country,
      province,
      city,
    } = data
    try {
      const res = await insertCustomer({
        belongerId,
        belongerName,
        belongerNameEn,
        businessId,
        companyAddress,
        companyId,
        companyName,
        customerCode,
        customerName,
        customerRank,
        customerSourceId,
        customerSourceName,
        customerSourceNameEn,
        customerType,
        dateSource,
        deletePaymentIds,
        email,
        faxNumber,
        highSeas,
        infoId,
        paymentId,
        phone,
        postcode,
        receivingAddressConfigDTOS,
        remark,
        seasTime,
        skype,
        tenantId,
        timeRemaining,
        website,
        wechat,
        whatsapp,
        auditObjectPaymentDTO,
        auditObjectDTO,
        country,
        province,
        city,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  //查询公司基本信息、支付方式、收货地址

  async getCompanyVO(companyId) {
    try {
      const res = await getCompanyVO(companyId)
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async insertPORemark({
    companyId,
    creator,
    creatorEn,
    customerRemarkId,
    infoId,
    remark,
    remarkType,
  }) {
    try {
      const res = await insertPORemark({
        companyId,
        creator,
        creatorEn,
        customerRemarkId,
        infoId,
        remark,
        remarkType,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async remarkListData({
    companyId,
    creator,
    creatorEn,
    customerRemarkId,
    infoId,
    remark,
    remarkType,
  }) {
    try {
      const res = await remarkListData({
        companyId,
        creator,
        creatorEn,
        customerRemarkId,
        infoId,
        remark,
        remarkType,
      })
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async selectAllCustomerByInfoIds(infoIds) {
    try {
      const res = await selectAllCustomerByInfoIds(infoIds)
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async selectAllCustomerByManagerIds(managerIds) {
    try {
      const res = await selectAllCustomerByManagerIds(managerIds)
      if (res && res.code === '000000') {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }

  async insertReceivingAddressApi(data) {
    return await insertReceivingAddress({
      infoId: data.infoId,
      receivingAddressConfigDTOS: data.receivingAddressConfigDTOS,
    })
  }

  async updateShipAddressApi(data) {
    return await updateShipAddress({
      infoId: data.infoId,
      receivingAddressConfigDTOS: data.receivingAddressConfigDTOS,
    })
  }
}

customerManageInteractor._instance = new customerManageInteractor()

const CustomerManageInteractor = customerManageInteractor.getInstance()
export default CustomerManageInteractor

function _trim(val) {
  return val && val.trim()
}
