import Vue from 'vue'
import { hasAccess } from '@/utils/hasAccess'
import { specialChars } from 'kits'

Vue.use(specialChars) // 禁止输入框输入特殊字符，特殊字符可以自定义

/**
 * @description 自定义指令v-permissions
 */
Vue.directive('permissions', {
  inserted(el, binding) {
    const { value } = binding
    if (value) {
      if (!hasAccess(value)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    }
  },
})

/**
 * @description 自定义指令v-drag
 */
Vue.directive('drag', {
  bind(el, binding, vNode) {
    if (
      el.querySelector('.el-dialog__header') &&
      el.querySelector('.el-dialog')
    ) {
      const dialogHeaderEl = el.querySelector('.el-dialog__header')
      const dragDom = el.querySelector('.el-dialog')
      dialogHeaderEl.style.cssText += ';cursor:move;'
      dragDom.style.cssText += ';top:0;'

      const getStyle = (function () {
        if (window.document.currentStyle) {
          return (dom, attr) => dom.currentStyle[attr]
        } else {
          return (dom, attr) => getComputedStyle(dom, null)[attr]
        }
      })()

      dialogHeaderEl.onmousedown = (e) => {
        const disX = e.clientX - dialogHeaderEl.offsetLeft
        const disY = e.clientY - dialogHeaderEl.offsetTop

        const dragDomWidth = dragDom.offsetWidth
        const dragDomHeight = dragDom.offsetHeight

        const screenWidth = document.body.clientWidth
        const screenHeight = document.body.clientHeight

        const minDragDomLeft = dragDom.offsetLeft
        const maxDragDomLeft = screenWidth - dragDom.offsetLeft - dragDomWidth

        const minDragDomTop = dragDom.offsetTop
        const maxDragDomTop = screenHeight - dragDom.offsetTop - dragDomHeight

        let styL = getStyle(dragDom, 'left')
        let styT = getStyle(dragDom, 'top')

        if (styL.includes('%')) {
          styL = +document.body.clientWidth * (+styL / 100)
          styT = +document.body.clientHeight * (+styT / 100)
        } else {
          styL = +styL.slice(0, -2)
          styT = +styT.slice(0, -2)
        }

        document.onmousemove = function (e) {
          let left = e.clientX - disX
          let top = e.clientY - disY

          if (-left > minDragDomLeft) {
            left = -minDragDomLeft
          } else if (left > maxDragDomLeft) {
            left = maxDragDomLeft
          }

          if (-top > minDragDomTop) {
            top = -minDragDomTop
          } else if (top > maxDragDomTop) {
            top = maxDragDomTop
          }

          dragDom.style.cssText += `;left:${left + styL}px;top:${top + styT}px;`

          vNode.child.$emit('drag-dialog')
        }

        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  },
})
/**
 * @description 限制输入框只能输入英文字母数字 并且首字母大写
 */
Vue.directive('inptRules', {
  inserted: function (e) {
    const el = e.querySelector('input')
    el.addEventListener('input', function (e) {
      //进行验证
      checkedfun(el)
    })
    function checkedfun(el) {
      // 为空退出
      if (!el.value) return
      let reg = new RegExp('^[\u4E00-\u9FA5]*$')
      if (!reg.test(el.value)) {
        el.value = el.value
          .replace(/[^\sA-Za-z0-9$]+/g, '')
          .replace(/\b\w+\b/g, (word) => {
            return word.substring(0, 1).toUpperCase() + word.substring(1)
          })
        //调用input事件使vue v-model绑定更新
        el.dispatchEvent(new Event('input'))
      }
    }
  },
})

Vue.directive('inptTextareaRules', {
  inserted: function (e) {
    const el = e.querySelector('textarea')
    el.addEventListener('textarea', function (e) {
      //进行验证
      checkedfun(el)
    })
    function checkedfun(el) {
      // 为空退出
      if (!el.value) return
      let reg = new RegExp('^[A-Z0-9][A-z0-9]*$')
      if (!reg.test(el.value)) {
        el.value = el.value
          .replace(/[^\sA-Za-z0-9]+/g, '')
          .replace(/\b\w+\b/g, (word) => {
            return word.substring(0, 1).toUpperCase() + word.substring(1)
          })
        //调用input事件使vue v-model绑定更新
        el.dispatchEvent(new Event('textarea'))
      }
    }
  },
})
Vue.directive('inptRulesNum', {
  inserted: function (e) {
    const el = e.querySelector('input')
    el.addEventListener('input', function (e) {
      //进行验证
      checkedfun(el)
    })
    function checkedfun(el) {
      // 为空退出
      if (!el.value) return
      let reg = new RegExp('/^[1-9]d*/')
      if (!reg.test(el.value)) {
        el.value = el.value.replace('.')

        //调用input事件使vue v-model绑定更新
        el.dispatchEvent(new Event('input'))
      }
    }
  },
})

/**
 * @description 输入框获取焦点
 */
Vue.directive('focus', {
  inserted: function (el) {
    el.querySelector('input').focus()
  },
})

Vue.directive('loadmore', {
  bind(el, binding) {
    let selector = binding.arg
      ? '.' + binding.arg
      : '.el-autocomplete-suggestion__wrap.el-scrollbar__wrap'
    // 如果指令使用时传了修饰符，需要特殊处理下。
    // bug场景：开户行通过插槽添加了名称和联行号下拉，此时querySelector会获取到第一个el-select-dropdown__list，为解决此问题，将目标类名的父级类名通过修饰符传递过来
    const modifierList = Object.keys(binding.modifiers)
    if (modifierList.length && modifierList[0] === 'bank-select') {
      selector = `.${modifierList[0]} ${selector}`
    }
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(selector)
    SELECTWRAP_DOM.addEventListener('scroll', function () {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (CONDITION) {
        binding.value()
      }
    })
  },
})

/**
 * 使用 v-only-number.positive.fill="3"
 *
 *    ="3" 表示允许输入小数，小数位数最多3位，默认为0
 *    positive 表示只能输入0和正数
 *    fill 表示自动补零。前提是允许输入小数，否则不要写fill修饰符
 *
 */

Vue.directive('only-number', {
  bind: function (el, { value = 0, modifiers }) {
    el = el.nodeName == 'INPUT' ? el : el.children[0]
    const RegStr =
      value == 0
        ? `^[\\+\\-]?\\d+\\d{0,0}`
        : `^[\\+\\-]?\\d+\\.?\\d{0,${value}}`
    el.addEventListener('keyup', function () {
      if (el.value != '-') {
        el.value = el.value.match(new RegExp(RegStr, 'g'))
        if (modifiers.positive) {
          el.value = el.value.replace('-', '')
        }
        el.dispatchEvent(new Event('input'))
      }
    })
    el.addEventListener('blur', function () {
      let num = el.value.match(new RegExp(RegStr, 'g')) || '0.00'
      // 自动补零
      if (modifiers.fill) {
        let str = num.toString()
        let decimalPosition = str.indexOf('.')
        if (decimalPosition < 0) {
          decimalPosition = str.length
          str += '.'
        }
        while (str.length <= decimalPosition + Number(value)) {
          str += '0'
        }
        num = str
        el.value = num
      }
      if (modifiers.positive) {
        el.value = el.value.replace('-', '')
      }
      el.dispatchEvent(new Event('input'))
    })
  },
})

// 点击当前区域之外的位置
Vue.directive('clickoutside', {
  // 初始化指令
  bind(el, binding) {
    const documentHandler = (e) => {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) return
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e)
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  unbind(el) {
    // 解除事件监听
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  },
})

/*
  限制输入框只能输入数字、字母、中文等规则

  使用指令：v-input

  修饰符参数说明：
    v-input.num 只能输入数字，默认不传修饰符，会自动限制只能输入数字
    v-input.intp 只能输入正整数
    v-input.num_alp 只能输入数字和字母
    v-input.num_alp_blank 只能输入数字、字母、空格
    v-input.num_alp_sym 只能输入数字和字母、英文符号、空格
    v-input.float 只能输入数字和小数点  v-input.float="2" 表示小数位数为2，默认小数位数为2，v-input.float="2"可以简写为v-input.float
    v-input.no_emoji 不能输入表情符号

*/
// 只能输入数字
function num(el) {
  el.value = el.value.replace(/\D+/g, '')
}

// 只能输入正整数
function intp(el) {
  const value = el.value.replace(/\D+/g, '') // 去掉非数字字符
  el.value = /^[1-9][0-9]*$/.test(value) ? value : value.replace(/^0+/, '') // 确保为正整数，去掉前导零
}

// 只能输入数字和字母
function num_alp(el) {
  el.value = el.value.replace(/[^A-Za-z0-9]/g, '')
}

// 只能输入数字、字母、空格
function num_alp_blank(el) {
  const regex = /[^a-zA-Z0-9 ]/g
  el.value = el.value.replace(regex, '')
}

// 只能输入数字、字母、英文符号、空格
function num_alp_sym(el) {
  const regex = /[^a-zA-Z0-9`~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/g
  el.value = el.value.replace(regex, '')
}

// 只能输入数字和小数点，n表示小数位数
function float(el, n) {
  let value = el.value
  value = value.replace(/[^\d.]/g, '') // 能数字和小数点
  value = value.replace(/^\./g, '') // 去掉开头的点
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.') // 处理多个点的情况
  if (n && Number(n) > 0) {
    const d = new Array(Number(n)).fill('\\d').join('') // 构建正则表达式
    const reg = new RegExp(`^(\\-)*(\\d+)\\.(${d}).*$`, 'ig')
    value = value.replace(reg, '$1$2.$3') // 限制小数位数
  }
  // if (value && !value.includes('.')) {
  //   value = Number(value).toString() // 去掉开头多个0
  // }
  el.value = value
}

// 限制表情：😀😂❤️🌟🎉🌍🐶☺
function no_emoji(el) {
  const regex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F1E6}-\u{1F1FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{263A}]+/gu
  el.value = el.value.replace(regex, '')
}

// 这里扩展限制的类型
const map = { num, intp, num_alp, num_alp_blank, num_alp_sym, float, no_emoji }

Vue.directive('input', {
  bind(el, binding, vnode) {
    el =
      el.querySelector('.el-input__inner') ||
      el.querySelector('.el-textarea__inner') ||
      el
    let lock = false // 标记是否需要锁定输入框
    let isHandling = false // 标记是否正在处理
    let lastValue = null
    // input事件处理函数
    const handler = () => {
      if (lock) return // 如果当前为锁定状态，则不进行处理
      if (isHandling) return // 如果已经在处理中，则不进行处理
      if (el.value === lastValue) return // 输入内容没有变化，则不进行处理
      isHandling = true // 设置标记为处理中
      const modifiers = Object.keys(binding.modifiers)
      const newModifier = modifiers[0] || 'num'
      map[newModifier](el, binding.value || 2)
      lastValue = el.value // 记录当前输入框的值
      Promise.resolve().then(() => {
        // 异步处理，场景：火狐浏览器中，需要在最后派发input事件
        el.dispatchEvent(new Event('input'))
      })
      isHandling = false // 处理完毕后设置标记为非处理状态
    }
    el.addEventListener('input', handler)
    // compositionstart和compositionend事件解决的bug场景：限制只能输入数字的输入框，先输入数字，再切换为中文输入法，输入字母时，会将原来的数字删掉
    el.addEventListener('compositionstart', () => {
      lock = true
    })
    el.addEventListener('compositionend', () => {
      lock = false
      el.dispatchEvent(new Event('input'))
    })
    // 当指令与元素解绑时，移除事件监听器
    vnode.context.$once('hook:destroyed', () => {
      el.removeEventListener('input', handler)
    })
  },
})

/**
 * @description 自定义指令v-clickoutside
 * 尾不允许带空格，多余空格提交时自动过滤
 * ● 多个字母中间只允许添加一个空格，多余空格提交时自动过滤
 * 英文规格中不允许添加中文空格和中文符号
 */
Vue.directive('filterSpace', {
  bind(el) {
    el.addEventListener('input', (event) => {
      let value = event.target.value
      // 确保 value 是字符串类型，防止意外类型报错
      if (typeof value !== 'string') {
        return
      }
      // 替换连续的多个空格为一个空格
      value = value.replace(/\s{2,}/g, ' ')

      // 替换常见的中文空格和中文符号
      value = value.replace(
        /[\u3000-\u303F\uFF00-\uFFEF\u2018-\u201D\u2026]/g,
        ''
      )

      // 更新输入框的值
      event.target.value = value

      // 恢复光标位置以提供更好的用户体验
      const cursorPos = event.target.selectionStart
      event.target.setSelectionRange(cursorPos, cursorPos)

      // 手动触发 input 事件以确保 Vue 绑定更新
      el.dispatchEvent(new Event('input'))
    })
  },
})
