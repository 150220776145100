import request from '@/core/services/request'

// 印刷计价配置-列表展示
export function listAll(data) {
  return request({
    url: '/product/productPrintingPricing/listAll',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//  印刷计价配置-新增
export function insertPO(data) {
  return request({
    url: '/product/productPrintingPricing/insertPO',
    method: 'post',
    data,
  })
}
//  印刷计价配置-更新
export function updatePO(data) {
  return request({
    url: '/product/productPrintingPricing/updatePO',
    method: 'post',
    data,
  })
}

//  印刷计价配置-根据ID删除
export function deleteById(data) {
  return request({
    url: '/product/productPrintingPricing/deleteById',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//  印刷计价配置-根据id查找
export function getDetail(data) {
  return request({
    url: '/product/productPrintingPricing/getDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//  印刷计价配置-根据分类及材质查询可用印刷方式
export function listForSelect(data) {
  return request({
    url: '/product/productPrintingPricing/listForSelect',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品类别-下拉框数据
export function categoryListForSelect() {
  return request({
    url: '/product/productCategory/listForSelect',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
// 产品管理-快速获取最低价
export function quickAccessMinFreight(data) {
  return request({
    url: '/product/product/quickAccessMinFreight',
    method: 'post',
    data,
  })
}

// 产品管理-查询产品计价阶梯中运输类型的运费

export function getFreightForProductStep(data) {
  return request({
    url: '/product/product/getFreightForProductStep',
    method: 'post',
    data,
  })
}

// 批量查询产品印刷方式、计价方式、印刷位置
export function listPrintingWay(data) {
  return request({
    url: '/product/product/listPrintingWay',
    method: 'post',
    data,
  })
}
