import request from '@/core/services/request'
// 标准库--分页列表查询
export function listPage(data) {
  return request({
    url: '/storage/stock/listPage',
    method: 'post',
    data,
  })
}

// 赠品库--分页列表查询
export function stockGiftListPage(data) {
  return request({
    url: '/storage/stockGift/listPage',
    method: 'post',
    data,
  })
}
// 标准库--导出库存

export function exportStock(params) {
  return request({
    url: '/storage/stock/export',
    method: 'post',
    params,
    responseType: 'blob', // 'ArrayBuffer'
  })
}

// 赠品库--获取产品备用数量

export function getGiftProductNum(skuId) {
  return request({
    url: '/storage/stockGift/getGiftProductNum',
    method: 'post',
    params: {
      skuId,
    },
  })
}

// 赠品库--修改库存

export function updateStockGiftNum(data) {
  return request({
    url: '/storage/stockGift/updateStockGiftNum',
    method: 'post',
    data,
  })
}

//库管理-根据skuId和仓库ID查询所有库位
export function findLocationBySku(params = {}) {
  return request({
    url: '/storage/warehouse/findLocationBySku',
    method: 'post',
    params,
  })
}

// findStockLocationBySku 仓库管理-根据skuId和仓库ID查询所有库位
export function findStockLocationBySku(params = {}) {
  return request({
    url: '/storage/warehouse/findStockLocationBySku',
    method: 'post',
    params,
  })
}

// 库存记录---根据分页查找列表
export function stockListPage(data) {
  return request({
    url: '/storage/stockRecords/listPage',
    method: 'post',
    data,
  })
}
//产品库存-仓库字段点击详情
export function stockInfoBySkuId(data) {
  return request({
    url: '/storage/stock/getStockInfoBySkuId',
    method: 'post',
    data,
  })
}
//采购单-根据sku查询在途采购单
export function inTransitBySku(data) {
  return request({
    url: '/order/purchaseOrder/inTransitBySku',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//入库单--库存不可用库存详情查询
export function warehouseInUnusableDetail(data) {
  return request({
    url: '/storage/warehouseIn/getWarehouseInUnusableDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//出库单--库存待出库详情查询
export function warehouseOutForStockDetail(data) {
  return request({
    url: '/storage/warehouseOut/getWarehouseOutForStockDetail',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//产品库存-根据sku查询库存锁定信息
export function stockLockInfo(data) {
  return request({
    url: '/storage/stockLockRecord/listStockLockInfo',
    method: 'post',
    data,
  })
}

//(采购订单)查看回显-权限判断
export function roleDetailForShow(data) {
  return request({
    url: '/order/purchaseOrder/roleDetailForShow',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

//仓库管理--判断当前登陆人是否有出入库单据的查看权限
export function checkGetDetailAuth(number) {
  return request({
    url: '/storage/warehouse/checkGetDetailAuth',
    method: 'post',
    params: {
      warehouseOrderNumber: number,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}
//仓库管理--判断当前登陆人是否有销售单号的查看权限
export function shippingLookOrderAuthority(data) {
  return request({
    url: 'order/salesOrder/shippingLookOrderAuthority',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 产品库存获取勾选项
 */
export function getUserFilterConditions(data = {}) {
  return request({
    url: 'storage/stockLookConfig/listPage',
    method: 'post',
    data,
  })
}

/**
 *保存用户勾选项
 */

export function saveUserFilterConditions(data = {}) {
  return request({
    url: '/storage/stockLookConfig/insertPO',
    method: 'post',
    data,
  })
}

/**
 * 仓库维度获取数据
 */
export function getListFromWrehouseDimensionView(data = {}) {
  return request({
    url: '/storage/stock/listPage',
    method: 'post',
    data,
  })
}

/***
 * 批次弹窗分页列表
 */

export function stockLotSearchListPage(data = {}) {
  return request({
    url: '/storage/stockLot/stockLotSearchListPage',
    method: 'post',
    data,
  })
}

// 仓库下拉项
export function findWarehouseList2(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 0,
    },
  })
}

// 根据产品编码查询产品id
export function productIdByCode(data) {
  return request({
    url: '/product/product/getProductIdByCode',
    method: 'post',
    data,
  })
}

// (采购订单)通过采购单号查找详情
export function dinggetDetailForShow(data) {
  return request({
    url: '/order/purchaseOrder/getDetailForShowEncode',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      encrypt: true,
    },
  })
}

// 采购单-解锁
export function stockLockRecordUnlock(data) {
  return request({
    url: '/storage/stockLockRecord/unLock',
    method: 'post',
    data,
  })
}

// 订单详情修改收货地址信息弹框
export function getOrderShipPlanVO(data = {}) {
  return request({
    url: '/storage/shippingPlan/v2/getOrderShipPlanVO',
    method: 'post',
    data,
  })
}
