const translateConfig = {
  zh: {
    AddOrder: {
      Create: '新增',
      Back: '返回',
      BasicInfo: '基本信息',
      QuoteNumber: '报价单号',
      OrderDate: '签约日期',
      CustomerPO: '客户订单号',
      ExportsRise: '出口抬头',
      SalesRep: '客户代表',
      OrderFrom: '订单来源',
      CustomerInfo: '客户信息',
      CompanyName: '公司名称',
      CustomerEmail: '客户邮箱',
      CustomerName: '客户姓名',
      Contacts: '联系人',
      ContactNumber: '联系电话',
      ProductList: '产品列表',
      CreateProduct: '新增产品',
      No: '序号',
      ProductImage: '产品图',
      ProductInfo: '产品信息',
      Specification: '规格',
      Stock: '库存',
      Quantity: '数量',
      UnitPrice: '单价 ($)',
      PrintingProof: '印刷效果图',
      ImprintMethod: '印刷方式',
      ProductDescription: '产品描述',
      ImprintDescription: '印刷描述',
      ArtFile: '印刷文件',
      OtherFees: '其他费用',
      FeeExplanation: '费用说明',
      Remark: '备注',
      OrderAssistant: '订单支持',
      Total: '总计',
      Operation: '操作',
      OffsetAmount: '客户抵扣金额',
      OrderTotalAmount: '订单总金额',
      ShippingInfo: '收货信息',
      ShippingMethods: '货运方式',
      EstimatedShipOutDate: '装运日期',
      EstimatedShipOutDate2: '预计发货日期',
      EstimatedDeliveryDate: '交货日期',
      LoadingPort: '装运港',
      DestinationPort: '目的港',
      ShippingAddress: '收货地址',
      Country: '国家',
      Company: '收货公司',
      Consignee: '收货人',
      ContactPhone: '联系电话',
      AddMore: '添加一行',
      CreateAddress: '新增地址',
      PaymentTerms: '收款方式',
      Currency: '订单币种',
      CustomerRemark: '客户备注',
      InternalRemark: '内部备注',
      Attachment: '附件',
      Review: '预览',
      Confirm: '确认',
      Save: '保存',
      Cancel: '取消',
      Edit: '编辑',
      Close: '关闭',
      CreateOrder: '新增订单',
      TotalShippingCost: '总运费',
      TotalQuantity: '总数量',
      TotalDiscount: '总折扣',
      OrderAmount: '产品总金额',
      ModeOfCargoDistribution: '货物分配方式',
      EqualDistribution: '平均分配',
      SelfDistribution: '自行分配',
      BindingProduct: '绑定产品',
      SelectProduct: '选取产品',
      OrderQuantity: '订单数量',
      QuantityAllotted: '分配数量',
      ClickToSelect: '点击选择',
      CustomerDiscounts: '客户折扣',
      Discount: '客户折扣说明',
      DiscountDescription: '折扣说明',
      DeductTheTotalAmount: '抵扣总金额',
      DeductibleAmount: '可抵扣金额',
      AmountDeductedFromThisOrder: '本单抵扣金额',
      Deduction: '抵扣金额',
      ReasonForModification: '修改原因',
      GeneratePI: '生成 PI',
      PrenatalSample: '产前样',
      updateShippingTips: '当前调整后的shipping fee 与 total shipping cost不等',
      costCalculationLable:
        '1、点击此按钮获取第三方运费报价，与实际运费可能存在差异；',
      costCalculationLable1:
        '2、此结果仅针对美国大陆，其他国家或偏远地区的运费请咨询产品运营或其他相关人员；',
    },
  },
  en: {
    AddOrder: {
      Create: 'Create',
      Back: 'Back',
      BasicInfo: 'Basic Info',
      QuoteNumber: 'Quote Number',
      OrderDate: 'Order Date',
      CustomerPO: 'Customer PO',
      ExportsRise: 'RP/RG/SW',
      SalesRep: 'Sales Rep',
      OrderFrom: 'Order From',
      CustomerInfo: 'Customer Info',
      CompanyName: 'Company',
      CustomerEmail: 'Customer Email',
      CustomerName: 'Customer Name',
      Contacts: 'Contacts',
      ContactNumber: 'Contact Number',
      ProductList: 'Product List',
      CreateProduct: 'Create Product',
      No: 'No',
      ProductImage: 'Product Image',
      ProductInfo: 'Product Info',
      Specification: 'Specification',
      Stock: 'Stock',
      Quantity: 'Quantity',
      UnitPrice: 'Unit Price ($)',
      PrintingProof: 'Virtual Proof',
      ImprintMethod: 'Imprint Method',
      ProductDescription: 'Product Description',
      ImprintDescription: 'Imprint Description',
      ArtFile: 'Art File',
      OtherFees: 'Other Fees',
      FeeExplanation: 'Fee Explanation',
      Remark: 'Remark',
      OrderAssistant: 'Sales Assistant',
      Total: 'Total',
      Operation: 'Operation',
      OffsetAmount: 'Offset Amount',
      OrderTotalAmount: 'Order Amount',
      ShippingInfo: 'Shipping Info',
      ShippingMethods: 'Shipping Methods',
      EstimatedShipOutDate: 'Estimated ShipOut Date',
      EstimatedShipOutDate2: 'Estimated Shipping Date',
      EstimatedDeliveryDate: 'Estimated Delivery Date',
      LoadingPort: 'Loading Port',
      DestinationPort: 'Destination Port',
      ShippingAddress: 'Shipping Address',
      Country: 'Country',
      Company: 'Company',
      Consignee: 'Consignee',
      ContactPhone: 'Contact Phone',
      AddMore: 'Add More',
      CreateAddress: 'Create Address',
      PaymentTerms: 'Payment Terms',
      Currency: 'Currency',
      CustomerRemark: ' Customer Remark',
      InternalRemark: 'Internal Remark',
      Attachment: 'Attachment',
      Review: 'Review',
      Confirm: 'Confirm',
      Save: 'Save',
      Cancel: 'Cancel',
      Edit: 'Edit',
      CreateOrder: 'Create Order',
      TotalShippingCost: 'Total Shipping Cost',
      TotalQuantity: 'Total Quantity',
      TotalDiscount: 'Total Discount',
      OrderAmount: 'Order Amount',
      ModeOfCargoDistribution: 'Mode of cargo distribution',
      EqualDistribution: 'Equal Distribution',
      SelfDistribution: 'Self Distribution',
      BindingProduct: 'Binding Product',
      SelectProduct: 'Select Product',
      OrderQuantity: 'Order Quantity',
      QuantityAllotted: 'Quantity Allotted',
      Close: 'Close',
      ClickToSelect: 'Click To Select',
      CustomerDiscounts: 'Customer Discounts',
      Discount: 'Discount',
      DiscountDescription: 'Discount Description',
      DeductTheTotalAmount: 'Deduct the total amount',
      DeductibleAmount: 'Deductible Amount',
      AmountDeductedFromThisOrder: 'Amount deducted from this order',
      Deduction: 'Deduction',
      ReasonForModification: 'Reason For Modification',
      GeneratePI: 'Generate PI',
      PrenatalSample: 'Physical Sample',
      updateShippingTips:
        'The current adjusted shipping fee is different from the total shipping cost',
      costCalculationLable:
        '1、Click this button to get a third-party shipping estimate. Please note this is only an estimate, actual shipping costs may vary.',
      costCalculationLable1:
        '2、This shipping estimate applies only to the continental United States. For shipping quotes to other countries or remote territories, please consult the Product Operations team or the appropriate staff.',
    },
  },
}

export default translateConfig
