import request from '@/core/services/request'
/**
 * 出运委托单列表 海运空运
 * @param {*} data
 * @returns
 */
export function getWTList(data) {
  return request({
    url: `/storage/shippingOrder/v2/listPage`,
    method: 'post',
    data,
  })
}

/**
 * 出运委托单列表 海运空运
 * @param {*} data
 * @returns
 */
export function getKDWTList(data) {
  return request({
    url: `/storage/express/v2/listPage`,
    method: 'post',
    data,
  })
}

/**
 * 导出
 * @param {*} data
 * @returns
 */

export function exportExcel({ shipOrderIdList }) {
  return request({
    url: `/storage/shippingOrder/v2/export/excel`,
    method: 'post',

    data: {
      shipOrderIdList,
    },
  })
}

/**
 * 快递导出
 * @param {*} data
 * @returns
 */
export function expressExport(data = {}) {
  return request({
    url: `/storage/express/v2/expressExport`,
    method: 'post',

    data,
  })
}

/**
 * 取消出运操作
 */

export function cancelConfirm(data) {
  return request({
    url: `/storage/shippingOrder/v2/cancel/confirm`,
    method: 'post',
    data,
  })
}

/**
 * 取消出运查询
 *
 */

export function cancelQuery(data) {
  return request({
    url: `/storage/shippingOrder/v2/cancel/query`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 批量更新单证员
 *
 */

export function updateClerkBatch(data = {}) {
  return request({
    url: `/storage/shippingOrder/v2/updateClerkBatch`,
    method: 'post',
    data,
  })
}

/**
 * 手动出运 仅“已出库”状态可以进行“手动出运”操作
 */

export function manualShip(shippingOrderIds = []) {
  return request({
    url: `/storage/shippingOrder/v2/manualShip`,
    method: 'post',
    data: shippingOrderIds,
  })
}

/**
 * 运输方式下拉数据
 * @param {*} data
 * @returns
 */

export function transpotwayList(data = {}) {
  return request({
    url: `/product/freightSetting/selectAll`,
    method: 'post',
    data,
  })
}

/**
 * 查看进程
 * @param {*} data
 * @returns
 */
export function getProgressList(data = {}) {
  return request({
    url: '/storage/shippingOrder/getList',
    method: 'post',
    data,
  })
}

export function manualSignIn(data = {}) {
  return request({
    // url: '/storage/shippingOrder/manualSignIn',
    url: '/storage/express/v2/manualSignIn',
    method: 'post',
    data,
  })
}

//批量手动签收
export function mutliManualSignIn(data) {
  return request({
    url: `/storage/express/v2/mutliManualSignIn`,
    method: 'post',
    data,
  })
}

/**
 * 保存运单号
 */

export function saveShipNo(data) {
  return request({
    url: `/storage/express/v2/saveShipNo`,
    method: 'post',
    data,
  })
}

/**
 * 获取仓库列表
 * @param {*} data
 * @returns
 */
export function findWarehouseList(data = {}) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data: {
      hasAuth: 0,
      hasCloudWarehouse: 1,
      ...data,
    },
  })
}

//根据产品编码查询产品id
export function productIdByCode(data) {
  return request({
    url: '/product/product/getProductIdByCode',
    method: 'post',
    data,
  })
}

// 出运推荐批次
export function lotRecommendArrange(data = {}) {
  return request({
    url: `/storage/stock/lotRecommendArrange`,
    method: 'post',
    data,
  })
}

// 采购需求出库申请-获取仓库配置
export function getStockCostConfigureVO(data) {
  return request({
    url: '/storage/costConfigure/getStockCostConfigureVO',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 产品库存批次查询（采购需求出库）
export function lotRecommend(data) {
  return request({
    url: '/storage/stock/lotRecommend',
    method: 'post',
    data,
  })
}

/**
 * 出库申请
 * @param {*} shipOrderId
 * @returns
 */

export function applyOutStock(data) {
  return request({
    url: `/storage/shippingOrder/v2/applyOutStock`,
    method: 'post',
    data,
  })
}

/**
 * 委托单详情
 * @param {*} shipOrderId
 * @returns
 */
export function getDetail(shippingOrderId) {
  return request({
    url: `/storage/shippingOrder/v2/getDetail`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    params: {
      shippingOrderId,
    },
  })
}

/**
 * 出库申请页面查询
 * @param {*} shipOrderId
 * @returns
 */

export function applyOutStockList(shipOrderProductId) {
  return request({
    url: `/storage/shippingOrder/v2/applyOutStock/page`,
    method: 'post',
    data: {
      shipOrderProductId,
    },
  })
}

/**
 * 上传发货附件
 */

export function uploadAccessory(data) {
  return request({
    url: `/storage/express/v2/uploadAccessory`,
    method: 'post',
    data,
  })
}

/**
 * 贸易条款
 * @param {*} data
 * @returns
 */
export function tradeList(data) {
  return request({
    url: `/order/orderAmount/listPage`,
    method: 'post',
    data,
  })
}

/**
 * 获取委托单出运修改信息
 *
 */

export function getUpdate(shippingOrderId) {
  return request({
    url: '/storage/shippingOrder/v2/getUpdate',
    method: 'post',
    params: {
      shippingOrderId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 修改委托单基础数据接口(不包含出运数量变化的修改)
 * @param {*} data
 * @returns
 */
export function updateBasic(data) {
  return request({
    url: '/storage/shippingOrder/v2/updateBasic',
    method: 'post',
    data,
  })
}

/**
 * 修改委托单 确认出运数量变化页面查询
 * @param {*} data
 * @returns
 */
export function getConfirmUpdate(data) {
  return request({
    url: '/storage/shippingOrder/v2/getConfirmUpdate',
    method: 'post',
    data,
  })
}

/**
 * 修改委托单-确认出运数量变化
 * @param {*} data
 * @returns
 */
export function confirmUpdate(data) {
  return request({
    url: '/storage/shippingOrder/v2/confirmUpdate',
    method: 'post',
    data,
  })
}

/**
 * 单证员查询
 * @returns
 */
export function clerkSettingList() {
  return request({
    url: '/order/clerkSetting/query',
    method: 'post',
  })
}

// 订单采购管理下拉仓库
export function storageFindWarehouseList2(data) {
  return request({
    url: '/storage/warehouse/findWarehouseList',
    method: 'post',
    data,
  })
}

// 出运物流待办
export function logisticsNeedToBe() {
  return request({
    url: `/storage/shippingOrder/v2/logisticsNeedToBe`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    loading: false,
  })
}

// 运单号查询物流信息
export function queryLogisticByShipNo(data) {
  return request({
    url: `/storage/shipNoLogistics/queryLogisticByShipNo`,
    method: 'post',
    data,
  })
}

// 签收邮件已发送
export function signMailSended(shippingOrderId) {
  return request({
    url: `/storage/shippingOrder/v2/signMailSended`,
    method: 'post',
    params: {
      shippingOrderId,
    },
  })
}

// 委托单查询物流信息
export function queryLogisticByShipOrder(data) {
  return request({
    url: `/storage/shipNoLogistics/queryLogisticByShipOrder`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 异常查询物流信息
export function queryLogisticByUnusual(data) {
  return request({
    url: `/storage/shipNoLogistics/queryLogisticByUnusual`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

// 出运物流异常待办处理
export function logisticsUnusualHandle(data) {
  return request({
    url: `/storage/shippingOrder/v2/logisticsUnusualHandle`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  })
}

/**
 * 下载
 * @param {*} data
 * @returns
 */
export function download(shipOrderIds) {
  return request({
    url: `/storage/shippingOrder/v2/download`,
    method: 'post',
    responseType: 'blob',
    data: {
      shipOrderIds,
    },
  })
}

// 快递单据，修改安排标记
export function arrangeFlag(data) {
  return request({
    url: `/storage/express/v2/arrangeFlag`,
    method: 'post',
    data,
  })
}

// 根据印刷方式、装运日期、货运方式计算产品交付日期
export function calculateDeliveryDate(data) {
  return request({
    url: `/product/product/calculate/deliveryDate`,
    method: 'post',
    data,
  })
}

// 根据印刷方式、计价方式、印刷位置计算产品装运日期
export function calculateShipDate(data) {
  return request({
    url: `/product/product/calculate/shipDate`,
    method: 'post',
    data,
  })
}
