// /**
//  * 业务逻辑层，可以获取entities层提供的实体类。将实体类提供实体相关的业务逻辑和interactor业务逻辑相结合一起提供给view层。
//  */

import {
  getUserFilterConditions,
  saveUserFilterConditions,
  getListFromWrehouseDimensionView,
  stockLotSearchListPage,
  shippingLookOrderAuthority,
  getOrderShipPlanVO,
} from '@/core/services/api/stock-center/stock'

class StockInteractor {
  static getInstance() {
    return this._instance
  }

  // 获取用户勾选项 不需要参数
  async getUserFilterConditions() {
    return await getUserFilterConditions()
  }
  // 保存用户勾选项
  async saveUserFilterConditions(data = {}) {
    const { noVirtualInventory, stockLookConfigId, wrehouseDimensionView } =
      data
    return await saveUserFilterConditions({
      noVirtualInventory,
      stockLookConfigId,
      wrehouseDimensionView,
    })
  }

  // 维度列表
  async getListFromWrehouseDimensionView(data = {}) {
    return await getListFromWrehouseDimensionView((data = {}))
  }

  //批次弹窗分页列表
  async stockLotSearchListPage(data) {
    console.log(data)
    const {
      oldSalesOrderNumber,
      orderBy,
      pageLe,
      pageNo,
      purchaseNumber,
      sku,
      tenantId,
      warehouseId,
      warehouseInNumber,
      salesOrderNumber,
      orderCode,
      useType,
      orderProductUniqueId,
      processOut,
    } = data
    return await stockLotSearchListPage({
      oldSalesOrderNumber,
      orderBy,
      pageLe,
      pageNo,
      purchaseNumber,
      sku,
      tenantId,
      salesOrderNumber,
      warehouseId,
      warehouseInNumber,
      orderCode,
      useType,
      orderProductUniqueId,
      processOut,
    })
  }

  /**
   * @description 出运查看订单详情权限（1：是；0否）
   * @param {*} param0
   * @returns
   */
  async shippingLookOrderAuthority({ orderCode }) {
    return await shippingLookOrderAuthority({
      orderCode,
    })
  }

  /**
   * @description 订单详情修改收货地址信息弹框
   * @param {*} piNumber
   * @returns
   */
  async getOrderShipPlanVOApi(piNumber) {
    return await getOrderShipPlanVO({
      piNumber,
    })
  }
}

StockInteractor._instance = new StockInteractor()

export default StockInteractor.getInstance()
