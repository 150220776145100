import Vue from 'vue'
import {
  getWTList, // 空海运列表
  getKDWTList, // 快递列表
  exportExcel, //导出excel
  expressExport, // 快递导出
  cancelConfirm, // 确认取消出运
  cancelQuery, // 取消出运查询
  updateClerkBatch, // 批量更新单证员
  manualShip, // 手动出运
  transpotwayList, // 运输方式列表
  getProgressList, // 进度列表
  manualSignIn, // 签收
  mutliManualSignIn, // 批量签收
  saveShipNo, //保存运单号
  findWarehouseList, //仓库列表
  productIdByCode, //根据产品编码查询产品id
  lotRecommendArrange, //出运推荐批次
  getStockCostConfigureVO, //仓库配置
  lotRecommend, // 批次推荐
  applyOutStock, // 出库申请
  getDetail, //获取委托单详情
  applyOutStockList, //出库申请页面查询
  uploadAccessory, //上传发货附件
  tradeList, //贸易条款
  getUpdate, //获取委托单出运修改信息
  updateBasic, // 修改委托单基础数据接口(不包含出运数量变化的修改)
  getConfirmUpdate, // 修改委托单 确认出运数量变化页面查询
  confirmUpdate, // 修改委托单-确认出运数量变化,
  clerkSettingList, //单证员查询
  storageFindWarehouseList2, //订单采购管理下拉仓库
  logisticsNeedToBe, //出运物流待办
  signMailSended, //签收邮件已发送
  queryLogisticByShipNo, //运单号查询物流信息
  queryLogisticByShipOrder, //委托单查询物流信息
  queryLogisticByUnusual, //异常查询物流信息
  logisticsUnusualHandle, //出运物流异常待办处理
  download, //空海运下载
  arrangeFlag, // 快递单据，修改安排标记
  calculateDeliveryDate, // 计算交付日期
  calculateShipDate, // 计算产品装运日期
} from '@/core/services/api/shipment/shipmentManage'

class shipmentMangeInteractor {
  static getInstance() {
    return this._instance
  }
  /**
   * 获取空海运委托单列表
   * @param {*} data
   * @returns
   */
  async getWTList(data = {}) {
    try {
      const {
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        drawbackFlag, // 是否退税
        mailSend,
        signMailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        pageLe,
        pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingMethodId,
        shippingMethodType,
        sku,
        state,
        status,
        tenantId,
        keyIds,
        wmsUpdateCancelFlag, // 安排状态
      } = data
      const res = await getWTList({
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        drawbackFlag, // 是否退税
        mailSend,
        signMailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        pageLe,
        pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingMethodId,
        shippingMethodType,
        sku,
        state,
        status,
        tenantId,
        keyIds,
        wmsUpdateCancelFlag, // 安排状态
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取快递委托单列表
   * @param {*} data
   * @returns
   */

  async getKDWTList(data = {}) {
    try {
      const {
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        drawbackFlag, // 是否退税
        mailSend,
        signMailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        pageLe,
        pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingMethodId,
        shippingMethodType,
        sku,
        state,
        status,
        tenantId,
        keyIds,
        wmsUpdateCancelFlag,
      } = data
      const res = await getKDWTList({
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        drawbackFlag, // 是否退税
        mailSend,
        signMailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        pageLe,
        pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingMethodId,
        shippingMethodType,
        sku,
        state,
        status,
        tenantId,
        keyIds,
        wmsUpdateCancelFlag,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 导出空海运EXCEL
   * @param {*} data
   * @returns
   */
  async exportExcel(data = {}) {
    try {
      const { shipOrderIdList } = data
      const res = await exportExcel({
        shipOrderIdList,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 快递导出
   * @param {*} data
   * @returns
   */
  async expressExport(data = {}) {
    try {
      let {
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        exportType,
        drawbackFlag, // 是否退税
        mailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        pageLe,
        pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingMethodId,
        shippingMethodType,
        shippingOrderId,
        sku,
        state,
        status,
        tenantId,
        users,
        wmsUpdateCancelFlag,
      } = data
      if (Array.isArray(shippingMethodId)) {
        shippingMethodId = shippingMethodId.join(',')
      }
      const res = await expressExport({
        businessId,
        businessNumber,
        clerkId,
        condition,
        createEndTime,
        createStartTime,
        creatorId,
        exportType,
        drawbackFlag, // 是否退税
        mailSend,
        manualSignEndTime,
        manualSignStartTime,
        number,
        orderBy,
        pageLe,
        pageNo,
        portShipment,
        productName,
        receivingAddress,
        shipEndTime,
        shipNo,
        shipStartTime,
        shippingMethodId,
        shippingMethodType,
        shippingOrderId,
        sku,
        state,
        status,
        tenantId,
        users,
        wmsUpdateCancelFlag,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 确认取消出运
   * @param {*} data
   * @returns
   */
  async cancelConfirm(data = {}) {
    try {
      const {
        mark,
        originFileNames,
        originFileUrls,
        shippingOrderCancelConfirmDtos,
        shippingOrderId,
      } = data
      const res = await cancelConfirm({
        mark,
        originFileNames,
        originFileUrls,
        shippingOrderCancelConfirmDtos,
        shippingOrderId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 取消出运查询
   * @param {*} data
   * @returns
   */
  async cancelQuery(data = {}) {
    try {
      const { shippingOrderId } = data
      const res = await cancelQuery({
        shippingOrderId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 批量更新单证员
   * @param {*} updateClerkDTOS
   * @returns
   */
  async updateClerkBatch(updateClerkDTOS) {
    try {
      const res = await updateClerkBatch(updateClerkDTOS)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 手动出运
   * @param {*} shippingOrderIds
   * @returns
   */
  async manualShip(shippingOrderIds) {
    try {
      const res = await manualShip(shippingOrderIds)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 运输方式列表
   * @param {*}
   * @returns
   */
  async transpotwayList() {
    try {
      const res = await transpotwayList()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取进度列表
   * @param {*} param0
   * @returns
   */
  async getProgressList({ orderNumber, tenantId, type }) {
    try {
      const res = await getProgressList({
        orderNumber,
        tenantId,
        type,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 签收
   * @param {*} param0
   * @returns
   */
  async manualSignIn({ manualSignInDate, businessNumber, shippingOrderId }) {
    try {
      const res = await manualSignIn({
        manualSignInDate,
        businessNumber,
        shippingOrderId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 批量签收
   * @param {*} manuaSigninDTOs
   * @returns
   */
  async mutliManualSignIn(manuaSigninDTOs) {
    try {
      manuaSigninDTOs = manuaSigninDTOs.map((item) => {
        const { businessNumber, manualSignInDate, shippingOrderId } = item
        return {
          businessNumber,
          manualSignInDate,
          shippingOrderId,
        }
      })
      const res = await mutliManualSignIn(manuaSigninDTOs)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 仓库管理-仓库下拉框数据
   * @param {*} shippingOrderShipNoDTOs
   * @returns
   */
  async findWarehouseList(data = {}) {
    const { belong, hasAuth, hasCloudWarehouse } = data
    try {
      const res = await findWarehouseList(data)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 保存运单号
   * @param {*} shippingOrderShipNoDTOs
   * @returns
   */
  async saveShipNo(shippingOrderShipNoDTOs) {
    try {
      shippingOrderShipNoDTOs = shippingOrderShipNoDTOs.map((item) => {
        const {
          shippingOrderShipNoId,
          arriveTime,
          caseNum,
          grossWeight,
          packingSize,
          remarks,
          shipNo,
          shippingMethod,
          shippingMethodId,
          shippingOrderId,
          volume,
          recordType,
          deleteFlag,
        } = item
        return {
          shippingOrderShipNoId,
          arriveTime,
          caseNum,
          grossWeight,
          packingSize,
          remarks,
          shipNo,
          shippingMethod,
          shippingMethodId,
          shippingOrderId,
          volume,
          recordType,
          deleteFlag,
        }
      })
      const res = await saveShipNo(shippingOrderShipNoDTOs)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 根据产品编码查询产品id
   * @param {*} param0
   * @returns
   */
  async productIdByCode({ productCode }) {
    try {
      const res = await productIdByCode({
        productCode,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 出运推荐批次
   * @param {*} param0
   * @returns
   */
  async lotRecommendArrange({
    mainFlag,
    num,
    orderProductUniqueId,
    recommendLotDTOs,
    salesOrderNumber,
    shippingPlanOrderProductId,
    skuId,
    tenantId,
    usable,
    warehouseId,
    warehouseName,
  }) {
    try {
      const res = await lotRecommendArrange({
        mainFlag,
        num,
        orderProductUniqueId,
        recommendLotDTOs,
        salesOrderNumber,
        shippingPlanOrderProductId,
        skuId,
        tenantId,
        usable,
        warehouseId,
        warehouseName,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 采购需求出库申请-获取仓库配置
   * @param {*} param0
   * @returns
   */
  async getStockCostConfigureVO({ warehouseId }) {
    try {
      const res = await getStockCostConfigureVO({
        warehouseId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 批次推荐
   * @param {*} param0
   * @returns
   */
  async lotRecommend({
    mainFlag,
    num,
    orderProductUniqueId,
    recommendLotDTOs,
    salesOrderNumber,
    shippingPlanOrderProductId,
    skuId,
    tenantId,
    usable,
    warehouseId,
    warehouseName,
  }) {
    try {
      const res = await lotRecommend({
        mainFlag,
        num,
        orderProductUniqueId,
        recommendLotDTOs,
        salesOrderNumber,
        shippingPlanOrderProductId,
        skuId,
        tenantId,
        usable,
        warehouseId,
        warehouseName,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 出库申请
   * @param {*} param0
   * @returns
   */
  async applyOutStock({
    businessNumber,
    number,
    shippingOrderApplyOutStockDTOList,
    shippingOrderId,
  }) {
    try {
      const res = await applyOutStock({
        businessNumber,
        number,
        shippingOrderApplyOutStockDTOList,
        shippingOrderId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 出运委托单详情
   * @param {*} shippingOrderId
   * @returns
   */
  async getDetail(shippingOrderId) {
    try {
      const res = await getDetail(shippingOrderId)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 出库申请页面查询
   * @param {*} shipOrderProductId
   * @returns
   */
  async applyOutStockList(shipOrderProductId) {
    try {
      const res = await applyOutStockList(shipOrderProductId)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 上传发货附件
   * @param {*} param0
   * @returns
   */
  async uploadAccessory({ shipAccessoryUrl, shippingOrderId }) {
    try {
      const res = await uploadAccessory({
        shipAccessoryUrl,
        shippingOrderId,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 贸易条款
   * @param {*} param0
   * @returns
   */

  async tradeList({ amountType }) {
    try {
      const res = await tradeList({
        amountType,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取委托单出运修改信息
   * @param {*} shippingOrderId
   * @returns
   */
  async getUpdate(shippingOrderId) {
    try {
      const res = await getUpdate(shippingOrderId)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 修改委托单基础数据接口(不包含出运数量变化的修改)
   * @param {*} shippingOrderId
   * @returns
   */
  async updateBasic({
    accessoryUrl,
    advanceFreight,
    clerkId,
    clerkName,
    country,
    deleteShippingOrderStockIds,
    deliveryDate,
    domesticLogistics,
    portShipment,
    productBoxUpdateDTOS,
    receivingAddress,
    receivingCompany,
    receivingPerson,
    receivingPhone,
    remarks,
    revokeFlag,
    shippingDate,
    shippingMethod,
    shippingMethodId,
    shippingMethodType,
    shippingOrderId,
    shippingOrderProductUpdateDTOS,
    trackingNo,
    trade,
    tradeId,
    unOutShippingOrderProductIds,
    emailAutoFlag,
  }) {
    try {
      const res = await updateBasic({
        accessoryUrl,
        advanceFreight,
        clerkId,
        clerkName,
        country,
        deleteShippingOrderStockIds,
        deliveryDate,
        domesticLogistics,
        portShipment,
        productBoxUpdateDTOS,
        receivingAddress,
        receivingCompany,
        receivingPerson,
        receivingPhone,
        remarks,
        revokeFlag,
        shippingDate,
        shippingMethod,
        shippingMethodId,
        shippingMethodType,
        shippingOrderId,
        shippingOrderProductUpdateDTOS,
        trackingNo,
        trade,
        tradeId,
        unOutShippingOrderProductIds,
        emailAutoFlag,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 修改委托单 确认出运数量变化页面查询
   * @param {*} shippingOrderId
   * @returns
   */
  async getConfirmUpdate({
    shippingOrderConfirmReturnDTOS,
    unOutShippingOrderProductIds,
  }) {
    try {
      const res = await getConfirmUpdate({
        shippingOrderConfirmReturnDTOS,
        unOutShippingOrderProductIds,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 修改委托单-确认出运数量变化
   * @param {*} param0
   * @returns
   */
  async confirmUpdate({
    mark,
    originFileNames,
    originFileUrls,
    shippingOrderId,
    shippingOrderProductUpdateStockDTOs,
    shippingOrderUpdateDTO,
  }) {
    try {
      const res = await confirmUpdate({
        mark,
        originFileNames,
        originFileUrls,
        shippingOrderId,
        shippingOrderProductUpdateStockDTOs,
        shippingOrderUpdateDTO,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 单证员查询
   */
  async clerkSettingList() {
    try {
      const res = await clerkSettingList()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 订单采购管理下拉仓库
   * @returns
   */
  async storageFindWarehouseList2() {
    try {
      const res = await storageFindWarehouseList2()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 出运物流待办
   */
  async logisticsNeedToBe() {
    try {
      const res = await logisticsNeedToBe()
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 运单号查询物流信息
   * @param {shipNos} 运单号
   * @param {shippingOrderId} 委托单id
   */
  queryLogisticByShipNo(params) {
    return queryLogisticByShipNo(params)
  }

  /**
   * @description 签收邮件已发送
   * @param {*} shippingOrderId
   * @returns
   */
  async signMailSended(shippingOrderId) {
    try {
      const res = await signMailSended(shippingOrderId)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 委托单查询物流信息
   * @param {shippingOrderId}
   */
  queryLogisticByShipOrder(id) {
    return queryLogisticByShipOrder({ shippingOrderId: id })
  }

  /**
   * 委托单查询物流信息
   * @param {shipNoLogisticsId}
   */
  queryLogisticByUnusual(id) {
    return queryLogisticByUnusual({ shipNoLogisticsId: id })
  }

  /**
   * 出运物流异常待办处理
   * @param {shipNoLogisticsId}
   */
  logisticsUnusualHandle(id) {
    return logisticsUnusualHandle({ shipNoLogisticsId: id })
  }

  /**
   * 空海运下载
   * @param {*} data
   * @returns
   */
  async download(shipOrderIds) {
    try {
      const res = await download(shipOrderIds)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 快递单据，修改安排标记
   * @param {arrangeFlag} 安排标记：0：未安排，1：已安排
   * @param {shippingOrderIds} 委托单ID
   */
  arrangeFlag(flag, ids) {
    return arrangeFlag({ arrangeFlag: flag, shippingOrderIds: ids })
  }

  calculateDeliveryDateApi(
    {
      amount, // 产品数量
      orderProductUniqueId, // 订单产品唯一id
      printingWayEn, // 印刷方式英文
      productId, // 产品id
      sku, // sku
      // shippingMethodType: transportType,
      productExtend: { planShippingDate, shippingMethodType: transportType },
    },
    addressList,
    quantType
  ) {
    return calculateDeliveryDate({
      addressList,
      amount,
      orderProductUniqueId,
      printingWayEn,
      productId,
      sku,
      planShippingDate,
      transportType,
      quantType,
    })
  }

  calculateShipDateApi(data) {
    const {
      amount,
      orderProductUniqueId,
      productId,
      sku,
      printingWayEn,
      productExtend: {
        printingPositionValueEn,
        valuationMethodValueEn,
        valuationMethodValue,
      },
    } = data
    return calculateShipDate({
      amount,
      orderProductUniqueId,
      printingPositionValueEn,
      printingWayEn,
      productId,
      sku,
      valuationMethodValueEn,
      valuationMethodValue,
    })
  }
}

shipmentMangeInteractor._instance = new shipmentMangeInteractor()

export const ShipmentMangeInteractor = shipmentMangeInteractor.getInstance()
